import {takeEvery, put, call, select} from 'redux-saga/effects';
import actions from './actions';
import RoleAPI from '../../api/role';
import {Role} from "../../models/role";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";

function* getRoles(payload) {
    let e = yield call(RoleAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_ROLES_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Role(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_ROLES_FAILED,
            error: result
        });
    }
}

function* getRole(payload) {
    let e = yield call(RoleAPI.getOne, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_ROLE_SUCCESS,
            data: new Role(e.response.data)
        });
    } else {
        yield put({
            type: actions.GET_ROLE_FAILED,
            error: result
        });
    }
}

function* createRole(payload) {
    let e = yield call(RoleAPI.create, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Roles);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_ROLES_REQUEST,
            params: state
        });
        yield put({
            type: actions.CREATE_ROLE_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.CREATE_ROLE_FAILED,
            error: result
        });
    }
}

function* updateRole(payload) {
    let e = yield call(RoleAPI.update, payload.id, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Roles);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_ROLES_REQUEST,
            params: state
        });
        yield put({
            type: actions.UPDATE_ROLE_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.UPDATE_ROLE_FAILED,
            error: result
        });
    }
}

function* deleteRole(payload) {
    let e = yield call(RoleAPI.delete, payload.id);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Roles);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_ROLES_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_ROLE_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_ROLE_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_ROLES_REQUEST', getRoles);
    yield takeEvery('GET_ROLE_REQUEST', getRole);
    yield takeEvery('CREATE_ROLE_REQUEST', createRole);
    yield takeEvery('UPDATE_ROLE_REQUEST', updateRole);
    yield takeEvery('DELETE_ROLE_REQUEST', deleteRole);
}
