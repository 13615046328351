import axios from "axios/index";
import {root} from "../redux/api.config";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'import';

export default class ImportAPI {

    static import(subresource) {
        const options = {
            url: `${root}/api/${resource}/${subresource}`,
            method: 'get',
            headers: authHeader()
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static importForId(subresource, id) {
        const options = {
            url: `${root}/api/${resource}/${subresource}/${id}`,
            method: 'get',
            headers: authHeader()
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

}