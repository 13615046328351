import React, {Component} from "react";
import PageHeader from "../../components/pageHeader";
import {Button, Row, Col, Empty, Icon, Layout, Menu, Spin, Tabs} from "antd";
import {connect} from "react-redux";
import actions from "../../redux/product/actions";
import categoryListActions from "../../redux/category-list/actions";
import {WrappedProductForm} from "./ProductForm";
import {Product} from "../../models/product";
import 'react-lightbox-component/build/css/index.css';
import CheckError from "../../components/checkError";

const {Content} = Layout;
const {getProduct, clearProduct, createProduct, updateProduct} = actions;
const {getCategoryList} = categoryListActions;
const {TabPane} = Tabs;

export class ProductContainer extends Component {

    componentDidMount() {
        //console.log('componentDidMount');
        this.props.getCategoryList(this.props.categoryList);
        if (this.props.match.params.id) {
            this.props.getProduct(this.props.match.params.id);
        } else {
            this.props.clearProduct();
        }
    };

    /**
     * Zwraca referencję do formularza edycji
     * @param formRef
     */
    saveEditFormRef = (formRef) => {
        this.editFormRef = formRef;
    }

    /**
     * Zapisuje dane
     * @param e
     */
    handleSave = e => {
        e.preventDefault();
        const form = this.editFormRef.props.form;
        form.validateFieldsAndScroll((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }

            //wysyłka danych do serwera
            const p = new Product();
            let data = p.getData(values);
            console.log('Received values of form: ', data);

            if (this.props.match.params.id) {
                this.props.updateProduct(this.props.match.params.id, data, this.props.history);
            } else {
                this.props.createProduct(data);
            }

            //wyczyszczenie formularza
            form.resetFields();
        });
    };

    /**
     * Anuluje edycję
     */
    handleCancel = () => {
        const form = this.editFormRef.props.form;
        //wyczyszczenie formularza
        form.resetFields();

        this.props.history.push('/store/products');
    }

    render() {
        const product = this.props.products.data;
        //console.log(product);
        let title = this.props.match.params.id ? product.name : 'Nowy produkt';

        let params = [];
        if (product.params && product.params.length > 0) {
            params = product.params.map((param) => {
                return <li key={param.id}><b>{param.param_name}</b>: {param.param_value}</li>;
            });
        }

        let models = [];
        if (product.models && product.models.length > 0) {
            models = product.models.map((model) => {
                return <li key={model.id}>{model.manufacturer_name} {model.name}</li>;
            });
        }

        return <Layout style={{padding: '0 24px 24px'}}>
            <PageHeader>{title}</PageHeader>
            <Content style={{
                background: '#fff',
                padding: 24,
                margin: 0,
            }}>
                <Tabs defaultActiveKey="1" onChange={() => {
                }}>
                    <TabPane tab="Dane ogólne" key="1">
                        <CheckError error={this.props.products.error} history={this.props.history}/>
                        <Spin spinning={this.props.products.loading}>
                            <WrappedProductForm
                                wrappedComponentRef={this.saveEditFormRef}
                                handleSave={this.handleSave}
                                handleCancel={this.handleCancel}
                                id={this.props.match.params.id}
                                data={product}
                                categories={this.props.categoryList.items}
                            />
                        </Spin>
                    </TabPane>

                    <TabPane tab="Specyfikacja" key="2">
                        <Row>
                            <Col span={12}>
                                <h3>Parametry</h3>
                                <ul>
                                    {params}
                                </ul>
                            </Col>

                            <Col span={12}>
                                <h3>Kompatybilność z urządzeniami</h3>
                                <ul>
                                    {models}
                                </ul>
                            </Col>
                        </Row>

                        <div style={{marginTop: '1.5em'}}>
                            <Button type="secondary"
                                    icon="arrow-left"
                                    title="Wróć do listy produktów"
                                    onClick={this.handleCancel}
                                    style={{marginLeft: 8}}>
                                Wróć
                            </Button>
                        </div>
                    </TabPane>

                </Tabs>

            </Content>
        </Layout>;
    }
}

const mapStateToProps = (state) => ({
    products: state.Products,
    categoryList: state.CategoryList
});

export default connect(mapStateToProps, {
    getProduct,
    clearProduct,
    createProduct,
    updateProduct,
    getCategoryList
})(ProductContainer);
