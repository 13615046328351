
const actions = {
    GET_STAT_REQUEST: 'GET_STAT_REQUEST',
    GET_STAT_SUCCESS: 'GET_STAT_SUCCESS',
    GET_STAT_FAILED: 'GET_STAT_FAILED',
    getStat: (params) => ({
        type: actions.GET_STAT_REQUEST,
        params: params
    }),

};

export default actions;
