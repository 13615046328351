import React from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Button, Col, Row, Form, Input, Table, Icon, Avatar} from "antd";
import {Product} from "../../models/product";
import {connect} from "react-redux";
import actions from "../../redux/product/actions";
import CheckError from "../../components/checkError";
import DeleteModal from "../../components/deleteModal";
import {Container} from "../Container";
import ImportProgress from "../../components/importProgress";

const {Content} = Layout;
const {
    getProducts,
    deleteProduct,
    createProduct,
    updateProduct,
    importProducts,
    getProductsPdf,
    getProductsCsv
} = actions;

export class ProductsContainer extends Container {

    state = {
        editModalVisible: false,
        editModalTitle: '',
        deleteModalVisible: false,
        product: {},
        fullText: ''
    };

    resource = 'products';
    model = new Product();
    getResource = (params) => {
        this.props.getProducts(params);
    };

    componentDidMount() {
        if (this.props.products.fullText !== this.state.fullText) {
            this.setState({fullText: this.props.products.fullText});
        }
        this.getResource(this.props[this.resource]);
    };

    /**
     * Drukuje dane
     */
    print = () => {
        this.props.getProductsPdf({});
    }

    /**
     * Pobiera dane
     */
    download = () => {
        this.props.getProductsCsv({});
    }

    /**
     * @description Otwiera okno usuwania danych
     */
    showDeleteModal = (record) => {
        this.setState({
            product: record,
            deleteModalVisible: true
        });
    }

    /**
     * @description Usuwa dane
     */
    handleDeleteOk = () => {
        this.props.deleteProduct(this.state.product.id);
        this.setState({
            product: {},
            deleteModalVisible: false
        });
    }

    /**
     * @description Anuluje usuwanie danych
     */
    handleDeleteCancel = () => {
        this.setState({
            deleteModalVisible: false
        });
    }

    /**
     * Otwiera okno dodawania danych
     * @param record
     */
    showAddModal = (record) => {
        this.setState({
            editModalVisible: true,
            editModalTitle: 'Dodaj produkt',
            product: new Product()
        });
    }

    /**
     * Otwiera okno edycji danych
     * @param record
     */
    showEditModal = (record) => {
        this.setState({
            editModalVisible: true,
            editModalTitle: record.name,
            product: record
        });
    }

    /**
     * Zapisuje dane
     */
    handleSave = () => {
        const form = this.editFormRef.props.form;
        form.validateFields((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }
            //console.log(values);
            //wysyłka danych do serwera
            const m = new Product();
            if (this.state.product.id) {
                this.props.updateProduct(this.state.product.id, m.getData(values));
            } else {
                this.props.createProduct(m.getData(values));
            }

            //console.log('Received values of form: ', m.getData(values));

            //wyczyszczenie formularza
            form.resetFields();

            //zamknięcie okna edycji
            this.setState({
                editModalVisible: false
            });
        });
    }

    /**
     * Anuluje edycję danych
     */
    handleCancel = () => {
        this.setState({
            editModalVisible: false
        });
    }

    /**
     * Uruchomienie filtra i zamknięcie okienka
     * (właściwe fitrowanie obługiwane jest przez metodę handleTableChange)
     * @param selectedKeys
     * @param confirm
     */
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    }

    /**
     * Wyczyszczenie filtra i zamknięcie okienka
     * (właściwe czyszczenie fitra obługiwane jest przez metodę handleTableChange)
     * @param clearFilters
     */
    handleReset = (clearFilters) => {
        clearFilters();
    }

    handleResetFullText = () => {
        this.setState({fullText: ''}, () => {
            this.filterFullText('');
        });
    };

    handleKeyDownFullText = (e) => {
        if (e.key === 'Enter') {
            this.filterFullText(this.state.fullText);
        }
    }

    /**
     * Zwraca referencję do formularza edycji
     * @param formRef
     */
    saveEditFormRef = (formRef) => {
        this.editFormRef = formRef;
    }

    importProducts = () => {
        this.props.importProducts();
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.id || null,
            filteredValue: this.props[this.resource].filters.id || null,
            ...this.getColumnSearchProps('id'),
        }, {
            title: 'Zdjęcie',
            key: 'photo',
            render: (text, record) => {
                if (record.photo) {
                    return <Avatar src={record.photo}/>;
                } else {
                    return null;
                }
            }
        }, {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.name || null,
            filteredValue: this.props[this.resource].filters.name || null,
            ...this.getColumnSearchProps('name'),
        }, {
            title: 'Kategoria',
            dataIndex: 'category_name',
            key: 'category_name',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.category_name || null,
            filteredValue: this.props[this.resource].filters.category_name || null,
            ...this.getColumnSearchProps('category_name'),
        }, {
            title: 'Cena',
            dataIndex: 'price',
            key: 'price',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.price || null,
            className: 'column-money'
        }, {
            title: 'Akcje',
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <Button.Group>
                    <Button type="primary" icon="edit" title="Edytuj" onClick={(e) => {
                        this.props.history.push('/store/products/edit/' + record.id);
                        e.stopPropagation();
                    }}></Button>
                    <Button type="primary" icon="delete" title="Usuń" onClick={(e) => {
                        this.showDeleteModal(record);
                        e.stopPropagation();
                    }}></Button>
                </Button.Group>
            ),
        }
        ];
        return <div>
            <Layout style={{padding: '0 24px 24px'}}>
                <PageHeader>Produkty</PageHeader>
                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <Row>
                        <Col span={4}>
                            <Button.Group>
                                <Button icon="printer" onClick={this.print} title="Drukuj"></Button>
                                <Button icon="download" onClick={this.download}
                                        title="Pobierz"></Button>
                                <Button icon="reload" onClick={this.refresh}
                                        title="Odśwież"></Button>
                            </Button.Group>
                        </Col>
                        <Col span={20} style={{textAlign: 'right'}}>
                            <Form layout="inline">
                                <Form.Item>
                                    <Input
                                        placeholder="Szukaj"
                                        value={this.state.fullText}
                                        onChange={(e) => this.setState({fullText: e.target.value})}
                                        onKeyDown={this.handleKeyDownFullText}
                                        suffix={<Icon type="close"
                                                      onClick={this.handleResetFullText}/>}
                                        addonAfter={<Icon type="search"
                                                          onClick={(e) => this.filterFullText(this.state.fullText)}/>}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" icon="import" onClick={this.importProducts}
                                            title="Importuj">Importuj</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>

                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <ImportProgress data={this.props.products}/>
                    <CheckError error={this.props.products.error} history={this.props.history}/>
                    <Table
                        pagination={{
                            ...this.props.products.pagination,
                            pageSizeOptions: ['5', '10', '20'], // ręczna opcja liczby wyświetlanych rekordow na stronie
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: this.showTotalItemsDialog,
                        }}
                        columns={columns}
                        scroll={{x: 1000}}
                        dataSource={this.props.products.items}
                        loading={this.props.products.loading}
                        rowKey="id"
                        onChange={this.handleTableChange}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    //history.push(this.props.url + '/' + record.id)
                                },
                                onMouseEnter: () => {
                                },  // mouse enter row
                            };
                        }
                        }
                    />

                    <DeleteModal
                        title="Usuwanie produktu"
                        content={<div><p>Czy na pewno usunąć dane produktu: <i>{this.state.product.name}</i>?</p>
                        </div>}
                        visible={this.state.deleteModalVisible}
                        onDelete={this.handleDeleteOk}
                        onCancel={this.handleDeleteCancel}
                    >
                    </DeleteModal>
                </Content>
            </Layout>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    products: state.Products
});

export default connect(mapStateToProps, {
    getProducts,
    deleteProduct,
    createProduct,
    updateProduct,
    importProducts,
    getProductsPdf,
    getProductsCsv
})(ProductsContainer);
