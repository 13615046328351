const actions = {
    RECOVER_PASSWORD_REQUEST: 'RECOVER_PASSWORD_REQUEST',
    recoverPassword: (data) => ({
        type: actions.RECOVER_PASSWORD_REQUEST,
        data: data
    }),

    RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
    RECOVER_PASSWORD_FAILURE: 'RECOVER_PASSWORD_FAILURE',

    SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
    setPassword: (hash, data) => ({
        type: actions.SET_PASSWORD_REQUEST,
        hash: hash,
        data: data
    }),

    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    SET_PASSWORD_FAILURE: 'SET_PASSWORD_FAILURE',
};

export default actions;
