import {takeEvery, put, call, select} from 'redux-saga/effects';
import actions from './actions';
import SessionAPI from '../../api/session';
import {Session} from "../../models/session";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";

function* getSessions(payload) {
    let e = yield call(SessionAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_SESSIONS_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Session(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_SESSIONS_FAILED,
            error: result
        });
    }
}

function* getSession(payload) {
    let e = yield call(SessionAPI.getOne, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_SESSION_SUCCESS,
            data: new Session(e.response.data)
        });
    } else {
        yield put({
            type: actions.GET_SESSION_FAILED,
            error: result
        });
    }
}

function* deleteSession(payload) {
    let e = yield call(SessionAPI.delete, payload.id);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Sessions);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_SESSIONS_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_SESSION_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_SESSION_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_SESSIONS_REQUEST', getSessions);
    yield takeEvery('GET_SESSION_REQUEST', getSession);
    yield takeEvery('DELETE_SESSION_REQUEST', deleteSession);
}
