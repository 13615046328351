import Dictionaries from './dictionary/reducer';
import Users from './user/reducer';
import Auth from  './auth/reducer';
import Register from './register/reducer';
import Password from './password/reducer';
import Roles from './role/reducer';
import Sessions from './session/reducer';
import Settings from './settings/reducer';
import Imports from './import/reducer';
import Images from './image/reducer';
import Manufacturers from './manufacturer/reducer';
import ManufacturerList from './manufacturer-list/reducer';
import DeviceModels from './device-model/reducer';
import Products from './product/reducer';
import Categories from './category/reducer';
import CategoryList from './category-list/reducer';
import Stat from './stat/reducer';

export default {
  Dictionaries,
  Users,
  Auth,
  Register,
  Password,
  Roles,
  Sessions,
  Settings,
  Imports,
  Images,
  Manufacturers,
  ManufacturerList,
  DeviceModels,
  Products,
  Categories,
  CategoryList,
  Stat
};

