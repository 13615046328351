import {takeEvery, put, call} from 'redux-saga/effects';
import actions from './actions';
import RegisterAPI from '../../api/register';
import {checkError} from "../api.errors";

function* register(payload) {
    let e = yield call(RegisterAPI.register, payload.data);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.REGISTER_SUCCESS
        });
    } else {
        yield put({
            type: actions.REGISTER_FAILURE,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('REGISTER_REQUEST', register);
}
