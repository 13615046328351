import axios from "axios/index";
import {root} from "../redux/api.config";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'auth';

export default class AuthAPI {

    static login(data) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            data: data
        };
        /*if (data.username === 'admin' && data.password === 'admin123')
            return {
                response: {
                    status: 200,
                    data: {
                        token: 'abc3er',
                        id: 1
                    }
                }
            }*/
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static logout() {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'delete',
            headers: authHeader(),
        };
        /*return {
            response: {
                status: 200,
                data: {}
            }
        }*/
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

}