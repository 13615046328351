const actions = {
    IMPORT_DATA_REQUEST: 'IMPORT_DATA_REQUEST',
    IMPORT_DATA_SUCCESS: 'IMPORT_DATA_SUCCESS',
    IMPORT_DATA_FAILED: 'IMPORT_DATA_FAILED',
    importData: (resource, id) => ({
        type: actions.IMPORT_DATA_REQUEST,
        resource: resource,
        id: id
    }),

};

export default actions;
