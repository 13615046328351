import React, {Component} from 'react';
import {Icon, Layout, Menu} from "antd";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import actions from "./redux/settings/actions";

const {Sider} = Layout;
const {setSidebarCollapsed} = actions;

class Sidebar extends Component {

    onCollapse = (collapsed, type) => {
        //console.log('onCollapse', collapsed, type);
        if (type === 'clickTrigger') {
            this.props.setSidebarCollapsed(collapsed);
        }
    }

    onBreakpoint = (broken) => {
        //console.log(broken);
        if (broken) {
            this.props.setSidebarCollapsed(broken);
        }
    }

    render() {
        //console.log(this.props.settings.sidebar.collapsed);
        const {location} = this.props;
        const parts = location.pathname.split('/');
        const root = '/' + parts[1];
        const current = '/' + parts[1] + '/' + parts[2];
        return <Sider
            breakpoint="lg"
            onBreakpoint={this.onBreakpoint}
            collapsible
            collapsed={this.props.settings.sidebar.collapsed}
            onCollapse={this.onCollapse}
        >
            <Link to="/">
                <img className="logo" alt="logo" src="/img/logo.svg"/>
            </Link>
            <Menu theme="dark"
                  defaultOpenKeys={this.props.settings.sidebar.collapsed ? [] : [root]}
                  selectedKeys={[root, current]}
                  mode="inline">

                <Menu.Item key="/store/manufacturers">
                    <Link to="/store/manufacturers">
                        <Icon type="shop"/>
                        <span>Producenci</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key="/store/models">
                    <Link to="/store/models">
                        <Icon type="printer"/>
                        <span>Modele urządzeń</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key="/store/categories">
                    <Link to="/store/categories">
                        <Icon type="bars"/>
                        <span>Kategorie</span>
                    </Link>
                </Menu.Item>

                <Menu.Item key="/store/products">
                    <Link to="/store/products">
                        <Icon type="tag"/>
                        <span>Produkty</span>
                    </Link>
                </Menu.Item>

                <Menu.SubMenu
                    key="/users"
                    title={
                        <span>
                  <Icon type="user"/>
                  <span>Użytkownicy</span>
                </span>
                    }
                >
                    <Menu.Item key="/users/users">
                        <Link to="/users/users">
                            Użytkownicy
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="/users/roles">
                        <Link to="/users/roles">
                            Role
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="/users/sessions">
                        <Link to="/users/sessions">
                            Sesje
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>

                <Menu.Item key="/lists/dictionaries">
                    <Link to="/lists/dictionaries">
                        <Icon type="setting"/>
                        <span>Słowniki</span>
                    </Link>
                </Menu.Item>


            </Menu>

        </Sider>
    }
}

const RouterSidebar = withRouter(Sidebar);

const mapStateToProps = (state) => ({
    settings: state.Settings
});

export default connect(mapStateToProps, {
    setSidebarCollapsed
})(RouterSidebar);
