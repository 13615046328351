import {takeEvery, put, call, select} from 'redux-saga/effects';
import actions from './actions';
import CategoryAPI from '../../api/category';
import {Category} from "../../models/category";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";
import {displayAsPdf, downloadAsCsv} from "../../helpers/Utility";

function* getCategories(payload) {
    let e = yield call(CategoryAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_CATEGORIES_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Category(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_CATEGORIES_FAILED,
            error: result
        });
    }
}

function* getCategory(payload) {
    let e = yield call(CategoryAPI.getOne, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_CATEGORY_SUCCESS,
            data: new Category(e.response.data)
        });
    } else {
        yield put({
            type: actions.GET_CATEGORY_FAILED,
            error: result
        });
    }
}

function* createCategory(payload) {
    let e = yield call(CategoryAPI.create, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Categories);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_CATEGORIES_REQUEST,
            params: state
        });
        yield put({
            type: actions.CREATE_CATEGORY_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.CREATE_CATEGORY_FAILED,
            error: result
        });
    }
}

function* updateCategory(payload) {
    let e = yield call(CategoryAPI.update, payload.id, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Categories);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_CATEGORIES_REQUEST,
            params: state
        });
        yield put({
            type: actions.UPDATE_CATEGORY_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.UPDATE_CATEGORY_FAILED,
            error: result
        });
    }
}

function* deleteCategory(payload) {
    let e = yield call(CategoryAPI.delete, payload.id);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Categories);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_CATEGORIES_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_CATEGORY_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_CATEGORY_FAILED,
            error: result
        });
    }
}

function* getCategoriesPdf(payload) {
    let e = yield call(CategoryAPI.getListPdf, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        displayAsPdf(e.response.data, 'manufacturers.pdf');

        yield put({
            type: actions.GET_CATEGORIES_PDF_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_CATEGORIES_PDF_FAILED,
            error: result
        });
    }
}

function* getCategoriesCsv(payload) {
    let e = yield call(CategoryAPI.getListCsv, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        downloadAsCsv(e.response.data, 'categories.csv');

        yield put({
            type: actions.GET_CATEGORIES_CSV_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_CATEGORIES_CSV_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_CATEGORIES_REQUEST', getCategories);
    yield takeEvery('GET_CATEGORY_REQUEST', getCategory);
    yield takeEvery('CREATE_CATEGORY_REQUEST', createCategory);
    yield takeEvery('UPDATE_CATEGORY_REQUEST', updateCategory);
    yield takeEvery('DELETE_CATEGORY_REQUEST', deleteCategory);
    yield takeEvery(actions.GET_CATEGORIES_PDF_REQUEST, getCategoriesPdf);
    yield takeEvery(actions.GET_CATEGORIES_CSV_REQUEST, getCategoriesCsv);
}
