import React, {Component} from 'react';
import {Button, Icon, Input, message, Upload} from "antd";

export default class ImageUpload extends Component {
    static getDerivedStateFromProps(props, state) {
        // Should be a controlled component.
        if ('value' in props) {
            /*if (typeof props.value === 'string') {
                return {value: {text: props.value, id: 0}};
            }*/
            return {...(props.value || '')};
        }
        return null;
    }

    constructor(props) {
        super(props);

        let value = props.value || '';
        this.state = {
            loading: false,
            value: value
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value || ''});
        }
    }

    beforeUpload = (file) => {
        const fileType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';
        if (!fileType) {
            message.error('Można załadować tylko pliki JPG/PNG/SVG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Rozmiar obrazka nie może przekroczyć 2MB!');
        }
        return fileType && isLt2M;
    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({loading: true});
            return;
        }
        if (info.file.status === 'done') {
            this.setState({
                value: info.file.response.url,
                loading: false
            });
            this.triggerChange(info.file.response.url);
            if (this.props.onUpload) {
                this.props.onUpload();
            }
        }
    };

    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const {onChange} = this.props;
        if (onChange) {
            onChange(changedValue);
        }
    };

    render() {
        return (
            <span ref={this.props.myForwardedRef}>
            <Upload
                name={this.props.name}
                showUploadList={false}
                action={this.props.action}
                beforeUpload={this.beforeUpload}
                onChange={this.handleUpload}
            >
                <Button>
                    <Icon type={this.state.loading ? 'loading' : 'upload'}/> Kliknij, żeby załadować
                </Button>
            </Upload>
            </span>

        );
    }
}