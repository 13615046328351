import axios from "axios/index";
import {root} from "../redux/api.config";
import {ListRequest} from "../models/request";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'dictionary';
const subResource = 'item';

export default class DictionaryAPI {

    static getList(params: ListRequest) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'get',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getOne(id) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'get',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static create(data) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'post',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static update(id, data) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'put',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static delete(id) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'delete',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }


    static getAllItems(params: ListRequest) {
        const options = {
            url: `${root}/api/${resource}/all/${subResource}`,
            method: 'get',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getItems(dictionaryId, params: ListRequest) {
        const options = {
            url: `${root}/api/${resource}/${dictionaryId}/${subResource}`,
            method: 'get',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getOneItem(dictionaryId, itemId) {
        const options = {
            url: `${root}/api/${resource}/${dictionaryId}/${subResource}/${itemId}`,
            method: 'get',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static createItem(dictionaryId, data) {
        const options = {
            url: `${root}/api/${resource}/${dictionaryId}/${subResource}`,
            method: 'post',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static updateItem(dictionaryId, itemId, data) {
        const options = {
            url: `${root}/api/${resource}/${dictionaryId}/${subResource}/${itemId}`,
            method: 'put',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static deleteItem(dictionaryId, itemId) {
        const options = {
            url: `${root}/api/${resource}/${dictionaryId}/${subResource}/${itemId}`,
            method: 'delete',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }


}