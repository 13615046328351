/**
 * Modele są używane tylko ze względu na niespójność obiektów pochodzących z API z obiektami stosowanymi w aplikacji
 */
export class Model {

    map = {};

    setData = (data) => {
        if (Object.keys(this.map).length) {
            const apiProps = this.getAPIProperties();
            for (let i in data) {
                //if (key in this) {
                //if (this.hasOwnProperty(key)) {
                if (apiProps[i]) {
                    this[apiProps[i]] = data[i];
                }
                //}
            }
        } else {
            for (let i in data) {
                this[i] = data[i];
            }
        }
    }

    /**
     * "Odwraca" obiekt map zwracając właściwości jako wartości i wartości jako właściwości
     */
    getAPIProperties() {
        let r = {};
        for (let i in this.map) {
            r[this.map[i]] = i;
        }
        return r;
    }

    getData = (values) => {
        if (!values) {
            values = this;
        }
        let r = {};
        for (let i in values) {
            if (values[i] === undefined) {
                values[i] = null;
            }
            if (this.map[i]) {
                r[this.map[i]] = values[i];
            } else {
                r[i] = values[i];
            }
        }

        return r;
    }

    getAPIProperty(property) {
        return Object.keys(this.map).length ? this.map[property] : property;
    }

}
