import React, {Component} from "react";
import WrappedRegisterForm from "./RegisterForm";
import {connect} from "react-redux";
import actions from "../../redux/register/actions";
import CheckError from "../../components/checkError";
import InfoAlert from "../../components/infoAlert";

const {register} = actions;

export class RegisterContainer extends Component {

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (nextProps.registerState.registered) {
            //nextProps.history.push('/login');
        }
    }

    /**
     * Zwraca referencję do formularza
     * @param formRef
     */
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);

            this.props.register(values);

            //wyczyszczenie formularza
            form.resetFields();
        });
    }

    render() {
        return (<div style={{width: '550px'}}>
            <CheckError error={this.props.registerState.error}/>
            <InfoAlert message={this.props.registerState.message}/>
            <WrappedRegisterForm
                wrappedComponentRef={this.saveFormRef}
                handleSubmit={this.handleSubmit}
            />
        </div>);
    }
}

const mapStateToProps = (state) => ({
    registerState: state.Register,
});

export default connect(mapStateToProps, {
    register
})(RegisterContainer);