
const actions = {
    GET_MANUFACTURER_LIST_REQUEST: 'GET_MANUFACTURER_LIST_REQUEST',
    GET_MANUFACTURER_LIST_SUCCESS: 'GET_MANUFACTURER_LIST_SUCCESS',
    GET_MANUFACTURER_LIST_FAILED: 'GET_MANUFACTURER_LIST_FAILED',
    getManufacturerList: (params) => ({
        type: actions.GET_MANUFACTURER_LIST_REQUEST,
        params: params
    }),

};

export default actions;
