import axios from "axios/index";
import {root} from "../redux/api.config";

const resource = 'register';

export default class RegisterAPI {

    static register(data) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

}