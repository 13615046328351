import {Model} from "./model";

export class Product extends Model {

    id: number;
    category_id: number;
    category_name: string;
    name: string;
    url: string;
    photo: string;
    price: string;
    mfr_part: string;
    params: [];
    models: [];

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }
}
