import React, {Component} from "react";
import {Form, Input, Button, Icon, Row, Col, Select} from "antd";

const {Option} = Select;

class RoleForm extends Component {

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 18, offset: 6},
            },
        };

        const colStyle = {
            paddingLeft: '0.5em',
            paddingRight: '0.5em'
        }

        return <Form {...formItemLayout} onSubmit={this.props.handleSave}>
            <Form.Item label="Nazwa roli">
                {getFieldDecorator('name', {
                    rules: [
                        {
                            required: true,
                            message: 'Wpisz nazwę roli',
                        },
                    ],
                })(<Input/>)}
            </Form.Item>

            <Form.Item label="Ikona">
                {getFieldDecorator('icon')(<Input/>)}
            </Form.Item>

            <Form.Item label="Kolor ikony">
                {getFieldDecorator('color')(<Input/>)}
            </Form.Item>

            <Form.Item label="Opis">
                {getFieldDecorator('description', {})(<Input.TextArea rows={4}/>)}
            </Form.Item>


            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="primary"
                        icon="save"
                        title="Zapisz"
                        htmlType="submit">
                    Zapisz
                </Button>
                <Button type="secondary"
                        icon="close"
                        title="Anuluj"
                        onClick={this.props.handleCancel}
                        style={{marginLeft: 8}}>
                    Anuluj
                </Button>
            </Form.Item>

        </Form>;
    }
}

export const WrappedRoleForm = Form.create({
    //ustawia wartości w polach formularza
    mapPropsToFields(props) {
        let fields = {};

        for (const i in props.data) {
            //if (i == 'content' || i == 'solution' || i == 'categories' || i == 'questionKeys' || i == 'answerKeys' || i == 'corrects') {
            fields[i] = Form.createFormField({
                value: props.data[i],
            })
        }
        return fields;
    }
})(RoleForm);
