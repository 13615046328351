import React, {Component} from "react";
import {Form, Icon, Input, Button, Checkbox} from 'antd';
import { Link } from 'react-router-dom'
import {validatePassword} from "../../validators/password";

const WrappedSetPasswordForm = Form.create({})(
    class extends Component {

        state = {
            confirmDirty: false,
        };

        handleConfirmBlur = e => {
            const value = e.target.value;
            this.setState({confirmDirty: this.state.confirmDirty || !!value});
        };

        compareToFirstPassword = (rule, value, callback) => {
            const form = this.props.form;
            if (value && value !== form.getFieldValue('password')) {
                callback('Hasła różnią się od siebie!');
            } /*else if (value) {

            }*/ else {
                callback();
            }
        };

        validateToNextPassword = (rule, value, callback) => {
            const form = this.props.form;
            if (value && this.state.confirmDirty) {
                form.validateFields(['confirm'], {force: true});
            }
            callback();
        };

        render() {
            const {getFieldDecorator} = this.props.form;
            const {handleSubmit} = this.props;

            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 8},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
            };

            const tailFormItemLayout = {
                wrapperCol: {
                    xs: {
                        span: 24,
                        offset: 0,
                    },
                    sm: {
                        span: 16,
                        offset: 8,
                    },
                },
            };

            return (
                <div>
                    <h1 style={{
                        margin: '0 auto',
                        background: 'none',
                        padding: '2em 0 1em 0',
                        textAlign: 'center'
                    }}>Ustaw nowe hasło</h1>

                    <Form {...formItemLayout} onSubmit={handleSubmit}>

                        <Form.Item label="Hasło" hasFeedback>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Proszę wprowadzić hasło',
                                    },
                                    {
                                        validator: this.validateToNextPassword,
                                    },
                                    {
                                        min: 8,
                                        message: 'Hasło musi mieć przynajmniej 8 znaków'
                                    },
                                    {
                                        validator: validatePassword,
                                    },
                                ],
                            })(<Input.Password/>)}
                        </Form.Item>

                        <Form.Item label="Potwierdź hasło" hasFeedback>
                            {getFieldDecorator('confirm', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Proszę ponownie wprowadzić hasło',
                                    },
                                    {
                                        validator: this.compareToFirstPassword,
                                    },
                                ],
                            })(<Input.Password onBlur={this.handleConfirmBlur}/>)}
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                Zapisz
                            </Button>
                            <Link to="/login" style={{marginLeft: '1em'}}>
                                Zaloguj się
                            </Link>
                        </Form.Item>

                    </Form>
                </div>
            );
        }
    });

export default WrappedSetPasswordForm;