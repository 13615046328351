import React, {Component} from "react";
import {Button, Modal} from "antd";

export default class extends Component {

    render() {
        const {title, content, visible, onDelete, onCancel} = this.props;
        return (
            <Modal
                title={title}
                visible={visible}
                onOk={onDelete}
                onCancel={onCancel}
                footer={[
                    <Button icon="close" key="back" onClick={onCancel}>
                        Anuluj
                    </Button>,
                    <Button icon="delete" key="submit" type="primary" onClick={onDelete}>
                        Usuń
                    </Button>,
                ]}
            >
                {content}
                <p style={{color: 'red'}}>Uwaga, usuniętych danych nie da się przywrócić!</p>
            </Modal>
        );
    }
}