import {takeEvery, put, call, select} from 'redux-saga/effects';
import actions from './actions';
import DeviceModelAPI from '../../api/device-model';
import {DeviceModel} from "../../models/device-model";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";
import ManufacturerAPI from "../../api/manufacturer";
import {displayAsPdf, downloadAsCsv} from "../../helpers/Utility";

function* getDeviceModels(payload) {
    let e = yield call(DeviceModelAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_DEVICE_MODELS_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new DeviceModel(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_DEVICE_MODELS_FAILED,
            error: result
        });
    }
}

function* getDeviceModel(payload) {
    let e = yield call(DeviceModelAPI.getOne, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_DEVICE_MODEL_SUCCESS,
            data: new DeviceModel(e.response.data)
        });
    } else {
        yield put({
            type: actions.GET_DEVICE_MODEL_FAILED,
            error: result
        });
    }
}

function* createDeviceModel(payload) {
    let e = yield call(DeviceModelAPI.create, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.DeviceModels);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_DEVICE_MODELS_REQUEST,
            params: state
        });
        yield put({
            type: actions.CREATE_DEVICE_MODEL_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.CREATE_DEVICE_MODEL_FAILED,
            error: result
        });
    }
}

function* updateDeviceModel(payload) {
    let e = yield call(DeviceModelAPI.update, payload.id, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.DeviceModels);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_DEVICE_MODELS_REQUEST,
            params: state
        });
        yield put({
            type: actions.UPDATE_DEVICE_MODEL_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.UPDATE_DEVICE_MODEL_FAILED,
            error: result
        });
    }
}

function* deleteDeviceModel(payload) {
    let e = yield call(DeviceModelAPI.delete, payload.id);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.DeviceModels);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_DEVICE_MODELS_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_DEVICE_MODEL_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_DEVICE_MODEL_FAILED,
            error: result
        });
    }
}

function* getDeviceModelByManufacturer(manufacturerId) {
    const data = [];
    let e = yield call(DeviceModelAPI.getDeviceModelsByManufacturer, manufacturerId);
    if (e.response.data) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString('<select>' + e.response.data + '</select>', 'text/html');
        const options = htmlDoc.getElementsByTagName('option');
        for (var i in options) {
            if (options[i].value) {
                data.push({
                    id: options[i].value,
                    manufacturer_id: manufacturerId,
                    name: options[i].text
                });
            }
        }
    }
    return data;
}

function* importDeviceModels(payload) {
    let e1 = yield call(ManufacturerAPI.getList, {
        recordsPerPage: 100,
        page: 1
    });
    let result1 = checkError(e1);
    if (result1.success) {
        //pobranie stanu
        let state = yield select((state) => state.DeviceModels);

        const manufacturers = e1.response.data.items;
        const total = manufacturers.length;

        let success = true;
        let error = null;
        for (var i in manufacturers) {
            const data = yield call(getDeviceModelByManufacturer, manufacturers[i].id);

            let e = yield call(DeviceModelAPI.importDeviceModels, data);

            yield put({
                type: actions.SET_PROGRESS,
                percent: Math.round(100 * (parseInt(i) + 1) / total)
            });

            let result = checkError(e);
            success = success && result.success;
            if (!success) {
                error = result;
            }
        }

        if (success) {
            //odświeżenie widoku poprzez ponowne pobranie danych
            yield put({
                type: actions.GET_DEVICE_MODELS_REQUEST,
                params: state
            });
            yield put({
                type: actions.IMPORT_DEVICE_MODELS_SUCCESS,

            });
            //messages: e.response.data.messages
        } else {
            yield put({
                type: actions.IMPORT_DEVICE_MODELS_FAILED,
                error: error
            });
        }
    } else {
        yield put({
            type: actions.IMPORT_DEVICE_MODELS_FAILED,
            error: result1
        });
    }
}

function* getDeviceModelsPdf(payload) {
    let e = yield call(DeviceModelAPI.getListPdf, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        displayAsPdf(e.response.data, 'device-models.pdf');

        yield put({
            type: actions.GET_DEVICE_MODELS_PDF_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_DEVICE_MODELS_PDF_FAILED,
            error: result
        });
    }
}

function* getDeviceModelsCsv(payload) {
    let e = yield call(DeviceModelAPI.getListCsv, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        downloadAsCsv(e.response.data, 'device-models.csv');

        yield put({
            type: actions.GET_DEVICE_MODELS_CSV_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_DEVICE_MODELS_CSV_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_DEVICE_MODELS_REQUEST', getDeviceModels);
    yield takeEvery('GET_DEVICE_MODEL_REQUEST', getDeviceModel);
    yield takeEvery('CREATE_DEVICE_MODEL_REQUEST', createDeviceModel);
    yield takeEvery('UPDATE_DEVICE_MODEL_REQUEST', updateDeviceModel);
    yield takeEvery('DELETE_DEVICE_MODEL_REQUEST', deleteDeviceModel);
    yield takeEvery(actions.IMPORT_DEVICE_MODELS_REQUEST, importDeviceModels);
    yield takeEvery(actions.GET_DEVICE_MODELS_PDF_REQUEST, getDeviceModelsPdf);
    yield takeEvery(actions.GET_DEVICE_MODELS_CSV_REQUEST, getDeviceModelsCsv);
}
