import actions from './actions';

const initState = {
    loading: false,
    error: null,
    status: null
};


const importReducer = (state = initState, action) => {
    let audio;
    switch (action.type) {
        case actions.IMPORT_DATA_REQUEST:
            return {...state, loading: true, error: null, status: null};

        case actions.IMPORT_DATA_SUCCESS:
            audio = new Audio('/audio/successful.mp3');
            audio.play();
            return {
                ...state,
                loading: false,
                status: 'refresh'
            };

        case actions.IMPORT_DATA_FAILED:
            audio = new Audio('/audio/creaky-wood-door.mp3');
            audio.play();
            return {
                ...state,
                loading: false,
                error: action.error,
                status: 'refresh'
            };


        default:
            return state;
    }
};

export default importReducer;