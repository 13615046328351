import {Model} from "./model";
import moment from 'moment';

export class User extends Model {

    id: number;
    username: string;
    status: number;
    firstname: string;
    surname: string;
    email: string;
    active: boolean;
    phone: string;
    address: string;
    post_code: string;
    town: string;
    photo: string;
    picture: string;
    role_names: string;
    role_ids: [];
    roles: [];
    insert_time: string;
    update_time: string;

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }

    setData = (data) => {
        this.town = {id: 0, text: ''};
        for (let i in data) {
            if (i === 'active') {
                this[i] = data[i] === '0' ? false : true;
            } else {
                this[i] = data[i];
            }
        }
    }

    getData = (values) => {
        if (!values) {
            values = this;
        }
        let r = {};
        for (let i in values) {
            r[i] = values[i];

        }
        //console.log(r);
        return r;
    }
}
