import React, {Component} from "react";
import {Form, Icon, Input, Button, Checkbox, Layout} from 'antd';
import {Link} from "react-router-dom";

const {Header} = Layout;

const WrappedNormalLoginForm = Form.create({})(
    class extends Component {

        render() {
            const {getFieldDecorator} = this.props.form;
            const {handleSubmit} = this.props;
            return (
                <div>
                    <h1 style={{margin: '0 auto', background: 'none', padding: '2em 0 1em 0', textAlign: 'center'}}>Zaloguj się</h1>
                    <Form onSubmit={handleSubmit} className="login-form">
                        <Form.Item>
                            {getFieldDecorator('username', {
                                rules: [{required: true, message: 'Proszę wprowadzić nazwę użytkownika'}],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    placeholder="Nazwa użytkownika"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [{required: true, message: 'Proszę wprowadzić hasło'}],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                    type="password"
                                    placeholder="Hasło"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('remember', {
                                valuePropName: 'checked',
                                initialValue: true,
                            })(<Checkbox>Zapamiętaj mnie</Checkbox>)}
                            <Link className="login-form-forgot" to="/recover-password">
                                Nie pamiętam hasła
                            </Link>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Zaloguj
                            </Button>
                            Lub <Link to="/register">zarejestruj się</Link>
                        </Form.Item>
                    </Form>
                </div>
            );
        }
    });

export default WrappedNormalLoginForm;