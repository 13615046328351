import React, {Component} from "react";
import PageHeader from "../../components/pageHeader";
import {Button, Col, Empty, Icon, Layout, Menu, Row, Spin, Tabs} from "antd";
import {connect} from "react-redux";
import actions from "../../redux/user/actions";
import imageActions from "../../redux/image/actions";
import {WrappedUserForm} from "./UserForm";
import {User} from "../../models/user";
import 'react-lightbox-component/build/css/index.css';
import CheckError from "../../components/checkError";
import {root} from "../../redux/api.config";
import ReactPlayer from 'react-player'
import ImageGallery from "../../components/imageGallery";

const {Content} = Layout;
const {getUser, clearUser, createUser, updateUser} = actions;
const {deleteImage, downloadImage} = imageActions;
const {TabPane} = Tabs;

export class UserContainer extends Component {

    state = {
        videoUrl: null
    }

    componentDidMount() {
        //console.log('componentDidMount');
        if (this.props.match.params.id) {
            this.props.getUser(this.props.match.params.id);
        } else {
            this.props.clearUser();
        }
    };

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (nextProps.users.status === 'refresh') {
            //console.log('componentWillReceiveProps');
            nextProps.history.push('/users/users');
        }
        if (nextProps.images.status === 'refresh' && nextProps.images.status !== this.props.images.status) {
            this.props.getUser(this.props.match.params.id);
        }
    }

    /**
     * Zwraca referencję do formularza edycji
     * @param formRef
     */
    saveEditFormRef = (formRef) => {
        this.editFormRef = formRef;
    }

    /**
     * Zapisuje dane
     * @param e
     */
    handleSave = e => {
        e.preventDefault();
        const form = this.editFormRef.props.form;
        form.validateFieldsAndScroll((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }

            //wysyłka danych do serwera
            const u = new User();
            let data = u.getData(values);
            console.log('Received values of form: ', data);

            if (this.props.match.params.id) {
                this.props.updateUser(this.props.match.params.id, data);
            } else {
                this.props.createUser(data);
            }

            //wyczyszczenie formularza
            form.resetFields();
        });
    };

    /**
     * Anuluje edycję
     */
    handleCancel = () => {
        const form = this.editFormRef.props.form;
        //wyczyszczenie formularza
        form.resetFields();

        this.props.history.push('/users/users');
    }

    handleSelectVideo = (options) => {
        this.setState({videoUrl: options.key});

    }

    render() {
        const user = this.props.users.data;
        console.log(user);
        let title = this.props.match.params.id ? user.firstname + ' ' + user.surname : 'Nowy użytkownik';
        let images = [];
        if (user.images && user.images.length > 0) {
            images = user.images.map((value) => {
                return {
                    src: root + value.file_name,
                    title: value.file_name,
                    description: user.firstname + ' ' + user.surname
                };
            });
        }
        let videos = [];
        let videoList = [];
        if (user.videos && user.videos.length > 0) {
            videoList = user.videos.map((item) => (
                <Menu.Item key={root + item.file_name}>
                    <Icon type="video-camera"/> {item.file_name}
                </Menu.Item>));
        }
        //console.log(images);

        return <Layout style={{padding: '0 24px 24px'}}>
            <PageHeader>{title}</PageHeader>
            <Content style={{
                background: '#fff',
                padding: 24,
                margin: 0,
            }}>
                <Tabs defaultActiveKey="1" onChange={() => {
                }}>
                    <TabPane tab="Dane użytkownika" key="1">
                        <CheckError error={this.props.users.error} history={this.props.history}/>
                        <Spin spinning={this.props.users.loading}>
                            <WrappedUserForm
                                wrappedComponentRef={this.saveEditFormRef}
                                handleSave={this.handleSave}
                                handleCancel={this.handleCancel}
                                id={this.props.match.params.id}
                                data={user}
                                roles={this.props.dictionaries.roles}
                                countries={this.props.dictionaries.countries}
                            />
                        </Spin>
                    </TabPane>

                </Tabs>

            </Content>
        </Layout>;
    }
}

const mapStateToProps = (state) => ({
    users: state.Users,
    dictionaries: state.Dictionaries,
    images: state.Images
});

export default connect(mapStateToProps, {
    getUser,
    clearUser,
    createUser,
    updateUser,
    deleteImage,
    downloadImage
})(UserContainer);
