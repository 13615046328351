import React, {Component} from "react";
import {Typography} from 'antd/lib/index';

const {Title} = Typography;

class PageHeader extends Component {
    render() {
        return (
            <Title level={4}>{this.props.children}</Title>
        );
    }
}

export default PageHeader;
