
const actions = {
    GET_CATEGORY_LIST_REQUEST: 'GET_CATEGORY_LIST_REQUEST',
    GET_CATEGORY_LIST_SUCCESS: 'GET_CATEGORY_LIST_SUCCESS',
    GET_CATEGORY_LIST_FAILED: 'GET_CATEGORY_LIST_FAILED',
    getCategoryList: (params) => ({
        type: actions.GET_CATEGORY_LIST_REQUEST,
        params: params
    }),

};

export default actions;
