import {takeEvery, put, call} from 'redux-saga/effects';
import actions from './actions';
import ImageAPI from '../../api/image';
import {checkError} from "../api.errors";

function* deleteImage(payload) {
    let e = yield call(ImageAPI.deleteImage, payload.resource, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.DELETE_SUCCESS
        });
    } else {
        yield put({
            type: actions.DELETE_FAILED,
            error: result
        });
    }
}

function* downloadImage(payload) {
    let e = yield call(ImageAPI.downloadImage, payload.resource, payload.id, payload.url);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.DOWNLOAD_SUCCESS
        });
    } else {
        yield put({
            type: actions.DOWNLOAD_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery(actions.DELETE_REQUEST, deleteImage);
    yield takeEvery(actions.DOWNLOAD_REQUEST, downloadImage);
}
