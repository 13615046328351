import {Role} from "../../models/role";

const actions = {
    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILED: 'GET_ROLES_FAILED',
    getRoles: (params) => ({
        type: actions.GET_ROLES_REQUEST,
        params: params
    }),

    GET_ROLE_REQUEST: 'GET_ROLE_REQUEST',
    GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
    GET_ROLE_FAILED: 'GET_ROLE_FAILED',
    getRole: (id) => ({
        type: actions.GET_ROLE_REQUEST,
        id: id
    }),

    CLEAR_ROLE: 'CLEAR_ROLE',
    clearRole: () => ({
        type: actions.CLEAR_ROLE
    }),

    CREATE_ROLE_REQUEST: 'CREATE_ROLE_REQUEST',
    CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
    CREATE_ROLE_FAILED: 'CREATE_ROLE_FAILED',
    createRole: (data: Role) => ({
        type: actions.CREATE_ROLE_REQUEST,
        data: data
    }),

    UPDATE_ROLE_REQUEST: 'UPDATE_ROLE_REQUEST',
    UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_FAILED: 'UPDATE_ROLE_FAILED',
    updateRole: (id, data: Role) => ({
        type: actions.UPDATE_ROLE_REQUEST,
        id: id,
        data: data
    }),

    DELETE_ROLE_REQUEST: 'DELETE_ROLE_REQUEST',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_FAILED: 'DELETE_ROLE_FAILED',
    deleteRole: (id) => ({
        type: actions.DELETE_ROLE_REQUEST,
        id: id
    })

};

export default actions;
