import {takeEvery, select, put, call} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import actions from './actions';
import UserAPI from '../../api/user';
import {User} from "../../models/user";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";

function* getUsers(payload) {
    let e = yield call(UserAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_USERS_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new User(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_USERS_FAILED,
            error: result
        });
    }
}

function* getUser(payload) {
    let e = yield call(UserAPI.getOne, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_USER_SUCCESS,
            data: new User(e.response.data)
        });
    } else {
        yield put({
            type: actions.GET_USER_FAILED,
            error: result
        });
    }
}

function* createUser(payload) {
    let e = yield call(UserAPI.create, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Users);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_USERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.CREATE_USER_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.CREATE_USER_FAILED,
            error: result
        });
    }
}

function* updateUser(payload) {
    let e = yield call(UserAPI.update, payload.id, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Users);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_USERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.UPDATE_USER_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.UPDATE_USER_FAILED,
            error: result
        });
    }
}

function* deleteUser(payload) {
    let e = yield call(UserAPI.delete, payload.id);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Users);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_USERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_USER_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_USER_FAILED,
            error: result
        });
    }
}

function* importPicture(payload) {
    let e = yield call(UserAPI.importPicture, payload.userId, payload.fbId);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Users);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_USERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.IMPORT_PICTURE_SUCCESS,
        });
    } else {
        yield put({
            type: actions.IMPORT_PICTURE_FAILED,
            error: result
        });
    }
}

function* importId(payload) {
    let e = yield call(UserAPI.importId, payload.userId, payload.fbName);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Users);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_USERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.IMPORT_ID_SUCCESS,
        });
    } else {
        yield put({
            type: actions.IMPORT_ID_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_USERS_REQUEST', getUsers);
    yield takeEvery('GET_USER_REQUEST', getUser);
    yield takeEvery('CREATE_USER_REQUEST', createUser);
    yield takeEvery('UPDATE_USER_REQUEST', updateUser);
    yield takeEvery('DELETE_USER_REQUEST', deleteUser);
    yield takeEvery(actions.IMPORT_PICTURE_REQUEST, importPicture);
    yield takeEvery(actions.IMPORT_ID_REQUEST, importId);
}
