import actions from './actions';

let user = JSON.parse(localStorage.getItem('user'));
const initState = user ? { loggedIn: true, error: null, user } : {error: null};


const authReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };

        case actions.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };

        case actions.LOGIN_FAILURE:
            return {
                error: action.error
            };

        case actions.LOGOUT_REQUEST:
            return {};

        default:
            return state;
    }
};

export default authReducer;