import React from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Button, Col, Row, Icon, Avatar, Menu, Table, Form} from "antd";
import {connect} from "react-redux";
import actions from "../../redux/dictionary/actions";
import CheckError from "../../components/checkError";
import {Container} from "../Container";
import EditDictionaryItemModal from "./EditDictionaryItemModal"
import DeleteModal from "../../components/deleteModal";
import {DictionaryItem} from "../../models/dictionary";

const {Content} = Layout;
const {
    getDictionaries,
    getDictionaryItems,
    createDictionaryItem,
    updateDictionaryItem,
    deleteDictionaryItem
} = actions;

export class DictionariesContainer extends Container {

    resource = 'dictionaries';
    model = new DictionaryItem();
    getResource = (params) => {
        this.props.getDictionaryItems(this.state.dictionaryId, params);
    };

    constructor(props) {
        //console.log('constructor', props.dictionaries);
        super(props);
        this.state = {
            dictionaryModalVisible: false,
            dictionaryModalTitle: '',
            deleteDictionaryVisible: false,
            deleteDictionaryItemVisible: false,
            editDictionaryItemModalVisible: false,
            editDictionaryItemModalTitle: '',
            dictionaryId: props.dictionaries.dictionaryId,
            dictionary: {},
            item: {},
        };
    }

    componentDidMount() {
        const params = this.props.dictionaries;
        params.pagination.pageSize = 100;
        this.props.getDictionaries(params);
    };

    /**
     * @description Otwiera okno usuwania danych
     */
    showDeleteDictionaryItemModal = (record) => {
        console.log(record);
        this.setState({
            item: record,
            deleteDictionaryItemModalVisible: true
        });
    }

    /**
     * @description Usuwa dane
     */
    handleDeleteDictionaryItemOk = () => {
        this.props.deleteDictionaryItem(this.state.dictionaryId, this.state.item.item_id);
        this.setState({
            item: {},
            deleteDictionaryItemModalVisible: false
        });
    }

    /**
     * @description Anuluje usuwanie danych
     */
    handleDeleteDictionaryItemCancel = () => {
        this.setState({
            deleteDictionaryItemModalVisible: false
        });
    }

    /**
     * Obsługa paginacji, filtrowania i sortowania danych tabeli
     * @param pagination
     * @param filters
     * @param sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        let p = this.props.dictionaries;
        if (sorter.field && sorter.order) {
            p.sort = [{
                col: sorter.field,
                dir: sorter.order
            }];
        } else {
            p.sort = null;
        }
        if (pagination) {
            p.pagination = pagination;
        }

        if (Object.keys(filters).length !== 0) {
            p.filters = {};
            for (let i in filters) {
                if (filters[i][0]) {
                    p.filters[i] = filters[i][0];
                }
            }
        }
        this.props.getDictionaryItems(this.state.dictionaryId, p);
    };

    /**
     * Uruchomienie filtra i zamknięcie okienka
     * (właściwe fitrowanie obługiwane jest przez metodę handleTableChange)
     * @param selectedKeys
     * @param confirm
     */
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    }

    /**
     * Wyczyszczenie filtra i zamknięcie okienka
     * (właściwe czyszczenie fitra obługiwane jest przez metodę handleTableChange)
     * @param clearFilters
     */
    handleReset = (clearFilters) => {
        clearFilters();
    }

    handleSelectDictionary = (options) => {
        //console.log(options);
        this.setState({dictionaryId: options.key});
        const params = this.props.dictionaries;
        params.pagination.current = 1;
        this.props.getDictionaryItems(options.key, params);
    }

    /**
     * Zwraca referencję do formularza edycji
     * @param formRef
     */
    saveEditDictionaryItemFormRef = (formRef) => {
        this.editDictionaryItemFormRef = formRef;
    }

    /**
     * Otwiera okno dodawania danych
     * @param record
     */
    showAddDictionaryItemModal = (record) => {
        this.setState({
            editDictionaryItemModalVisible: true,
            editDictionaryItemModalTitle: 'Dodaj element do słownika',
            item: new DictionaryItem()
        });
    }

    /**
     * Otwiera okno edycji danych
     * @param record
     */
    showEditDictionaryItemModal = (record) => {
        this.setState({
            editDictionaryItemModalVisible: true,
            editDictionaryItemModalTitle: 'Edytuj element ' + record.item_id,
            item: record
        });
    }


    /**
     * Zapisuje element słownika
     */
    handleSaveDictionaryItem = () => {
        const form = this.editDictionaryItemFormRef.props.form;
        form.validateFields((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }

            //wysyłka danych do serwera
            const m = new DictionaryItem();
            if (this.state.item.item_id) {
                this.props.updateDictionaryItem(this.state.dictionaryId, this.state.item.item_id, m.getData(values));
            } else {
                this.props.createDictionaryItem(this.state.dictionaryId, m.getData(values));
            }

            console.log('Received values of form: ', m.getData(values));

            //wyczyszczenie formularza
            form.resetFields();

            //zamknięcie okna edycji
            this.setState({
                editDictionaryItemModalVisible: false
            });
        });
    }

    /**
     * Anuluje edycję
     */
    handleCancelDictionaryItem = () => {
        this.setState({
            editDictionaryItemModalVisible: false
        });
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'item_id',
            key: 'item_id',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.item_id || null,
        }, {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.name || null,
        }, {
            title: 'Ikona',
            key: 'icon',
            render: (text, record) => {
                if (record.icon) {
                    return <Icon type={record.icon}/>;
                } else {
                    return '';
                }
            }
        }, {
            title: 'Akcje',
            key: 'action',
            render: (text, record) => (
                <Button.Group>
                    <Button type="primary" icon="edit" title="Edytuj" onClick={(e) => {
                        this.showEditDictionaryItemModal(record);
                        e.stopPropagation();
                    }}></Button>
                    <Button type="primary" icon="delete" title="Usuń" onClick={(e) => {
                        this.showDeleteDictionaryItemModal(record);
                        e.stopPropagation();
                    }}></Button>
                </Button.Group>
            ),
        }
        ];
        const items = this.props.dictionaries.dictionaries.map((item) => (
            <Menu.Item key={item.id}>
                <Icon type="database"/> {item.name}
            </Menu.Item>));
        return <div>
            <Layout style={{padding: '0 24px 24px'}}>
                <PageHeader>Słowniki</PageHeader>
                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <CheckError error={this.props.dictionaries.error} history={this.props.history}/>
                    <Row>
                        <Col span={10}>
                            <Content style={{
                                paddingRight: 24,
                                margin: 0,
                            }}>
                                <Menu
                                    onSelect={this.handleSelectDictionary}
                                    mode="inline"
                                    selectedKeys={[this.state.dictionaryId]}
                                    //style={{width: 350}}
                                >{items}
                                </Menu>
                            </Content>
                        </Col>

                        <Col span={14}>
                            <Row>
                                <Col span={4}>
                                    <Button.Group>
                                        <Button icon="printer" title="Drukuj"></Button>
                                        <Button icon="download"
                                                title="Pobierz"></Button>
                                    </Button.Group>
                                </Col>
                                <Col span={20} style={{textAlign: 'right'}}>
                                    <Form layout="inline">
                                        <Form.Item>
                                            <Button type="primary"
                                                    icon="plus"
                                                    onClick={this.showAddDictionaryItemModal}
                                                    disabled={!this.state.dictionaryId}
                                            >Dodaj
                                                element</Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                            <Table
                                pagination={{
                                    ...this.props.dictionaries.pagination,
                                    pageSizeOptions: ['5', '10', '20'], // ręczna opcja liczby wyświetlanych rekordow na stronie
                                    showSizeChanger: false,
                                    showQuickJumper: false,
                                    showTotal: this.showTotalItemsDialog,
                                }}
                                columns={columns}
                                dataSource={this.props.dictionaries.items}
                                loading={this.props.dictionaries.loading}
                                rowKey="item_id"
                                onChange={this.handleTableChange}
                            />

                        </Col>
                    </Row>
                </Content>

                <EditDictionaryItemModal
                    wrappedComponentRef={this.saveEditDictionaryItemFormRef}
                    title={this.state.editDictionaryItemModalTitle}
                    visible={this.state.editDictionaryItemModalVisible}
                    onSave={this.handleSaveDictionaryItem}
                    onCancel={this.handleCancelDictionaryItem}
                    data={this.state.item}
                >
                </EditDictionaryItemModal>

                <DeleteModal
                    title="Usuwanie elementu słownika"
                    content={<div><p>Czy na pewno usunąć element: <i>{this.state.item.name}</i></p>
                    </div>}
                    visible={this.state.deleteDictionaryItemModalVisible}
                    onDelete={this.handleDeleteDictionaryItemOk}
                    onCancel={this.handleDeleteDictionaryItemCancel}
                >
                </DeleteModal>

            </Layout>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    dictionaries: state.Dictionaries,
});

export default connect(mapStateToProps, {
    getDictionaries,
    getDictionaryItems,
    createDictionaryItem,
    updateDictionaryItem,
    deleteDictionaryItem
})(DictionariesContainer);
