import {takeEvery, select, put, call} from 'redux-saga/effects';
import actions from './actions';
import PasswordAPI from '../../api/password';
import {checkError} from "../api.errors";

function* recoverPassword(payload) {
    let e = yield call(PasswordAPI.recoverPassword, payload.data);
    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.RECOVER_PASSWORD_SUCCESS,
            data: e.response.data
        });
    } else {
        yield put({
            type: actions.RECOVER_PASSWORD_FAILURE,
            error: result
        });
    }
}

function* setPassword(payload) {
    let e = yield call(PasswordAPI.setPassword, payload.hash, payload.data);
    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.SET_PASSWORD_SUCCESS,
            data: e.response.data
        });
    } else {
        yield put({
            type: actions.SET_PASSWORD_FAILURE,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('RECOVER_PASSWORD_REQUEST', recoverPassword);
    yield takeEvery('SET_PASSWORD_REQUEST', setPassword);
}
