import {Model} from "./model";

export class DeviceModel extends Model {

    id: number;
    manufacturer_id: number;
    manufacturer_name: string;
    manufacturer_seo_name: string;
    name: string;
    seo_name: string;
    url: string;
    product_count: number;

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }
}
