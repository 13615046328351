import {Model} from "./model";

export class Session extends Model {

    id: number;
    user_id: number;
    user_name: string;
    insert_time: string;
    expired: string;
    ip: string;
    os_id: number;
    os_name: string;
    os_version: string;
    browser_id: number;
    browser_name: string;
    browser_version: string;
    device_type_id: number;
    device_type_name: string;
    device_manufacturer_id: number;
    device_manufacturer_name: string;
    device_model: string;

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }
}