import actions from './actions';
import {Category} from "../../models/category";

const initState = {
    pagination: {
        pageSize: 20,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    items: [],
    data: new Category({}),
    error: null

};


const categoryReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_CATEGORIES_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_CATEGORIES_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {...state, loading: false, items: action.data,
                sort: action.sort,
                filters: action.filters,
                pagination: pagination};

        case actions.GET_CATEGORIES_FAILED:
            return {...state, loading: false, items: [], error: action.error};

        case actions.GET_CATEGORY_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_CATEGORY_SUCCESS:
            return {...state, loading: false, data: action.data};

        case actions.GET_CATEGORY_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.CLEAR_CATEGORY:
            return {...state, data: new Category({})};

        case actions.CREATE_CATEGORY_REQUEST:
            return {...state, loading: true, error: null};

        case actions.CREATE_CATEGORY_SUCCESS:
            return {...state, loading: false};

        case actions.CREATE_CATEGORY_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.UPDATE_CATEGORY_REQUEST:
            return {...state, loading: true, error: null};

        case actions.UPDATE_CATEGORY_SUCCESS:
            return {...state, loading: false};

        case actions.UPDATE_CATEGORY_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.DELETE_CATEGORY_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_CATEGORY_SUCCESS:
            return {...state, loading: false};

        case actions.DELETE_CATEGORY_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.GET_CATEGORIES_PDF_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_CATEGORIES_PDF_SUCCESS:
            return {...state, loading: false};

        case actions.GET_CATEGORIES_PDF_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_CATEGORIES_CSV_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_CATEGORIES_CSV_SUCCESS:
            return {...state, loading: false};

        case actions.GET_CATEGORIES_CSV_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default categoryReducer;
