import React, {Component} from 'react';
import {Button, Empty, Form, Icon, Input, message, Spin, Upload} from "antd";
import CheckError from "./checkError";
import ImageUpload from "./imageUpload";
import {root} from "../redux/api.config";
import Lightbox from "react-lightbox-component";

export default class ImageGallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            url: null
        };
    }

    render() {
        return (
            <div>
                <Spin spinning={this.props.loading}>
                    <Form layout="inline">
                        <Form.Item>
                            <ImageUpload name={this.props.name}
                                         action={this.props.uploadAction}
                                         onUpload={this.props.onUpload}/>
                        </Form.Item>
                        <Form.Item>
                            <Input.Search
                                value={this.state.url}
                                style={{width: "400px"}}
                                placeholder="Wpisz adres url"
                                enterButton={<Button
                                    disabled={this.state.url === null}>Pobierz</Button>}
                                onChange={(e) => {
                                    this.setState({url: e.target.value});
                                }}
                                onSearch={(url) => {
                                    this.props.onDownload(url);
                                    this.setState({url: null});
                                }}
                            />
                        </Form.Item>
                    </Form>
                    {this.props.data.length > 0 ?
                        <div style={{marginTop: '1em'}}>
                            <Lightbox images={this.props.data}
                                      renderImageFunc={(idx, image, toggleLightbox, width, height) => {
                                          let w = this.props.thumbnailWidth ? this.props.thumbnailWidth : width;
                                          let h = this.props.thumbnailHeight ? this.props.thumbnailHeight : height;
                                          let c = this.props.thumbnailClass ? this.props.thumbnailClass : "lightbox-img-thumbnail";
                                          return (
                                              <div key={idx} style={{display: 'inline-block'}}>
                                                  <img
                                                      src={image.src}
                                                      style={{width: w, height: h}}
                                                      className={c}
                                                      onClick={toggleLightbox.bind(null, idx)}/>
                                                  <div>
                                                      <Button type="link"
                                                              icon="delete"
                                                              title="Usuń"
                                                              onClick={() => {
                                                                  this.props.onDelete(image.src);
                                                              }}
                                                      />
                                                  </div>
                                              </div>
                                          )
                                      }}/>
                        </div>
                        : <Empty description={this.props.emptyDescription}/>}
                </Spin>
            </div>

        );
    }
}