import {takeEvery, put, call} from 'redux-saga/effects';
import actions from './actions';
import CategoryAPI from '../../api/category';
import {Category} from "../../models/category";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";

function* getCategoryList(payload) {
    let e = yield call(CategoryAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_CATEGORY_LIST_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Category(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_CATEGORY_LIST_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_CATEGORY_LIST_REQUEST', getCategoryList);
}
