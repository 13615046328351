import {Role} from "../../models/role";
import {DictionaryItem} from "../../models/dictionary";

const actions = {
    GET_DICTIONARIES_REQUEST: 'GET_DICTIONARIES_REQUEST',
    GET_DICTIONARIES_SUCCESS: 'GET_DICTIONARIES_SUCCESS',
    GET_DICTIONARIES_FAILED: 'GET_DICTIONARIES_FAILED',
    getDictionaries: (params) => ({
        type: actions.GET_DICTIONARIES_REQUEST,
        params: params
    }),

    GET_ALL_DICTIONARIES_REQUEST: 'GET_ALL_DICTIONARIES_REQUEST',
    GET_ALL_DICTIONARIES_SUCCESS: 'GET_ALL_DICTIONARIES_SUCCESS',
    GET_ALL_DICTIONARIES_FAILED: 'GET_ALL_DICTIONARIES_FAILED',
    getAllDictionaries: () => ({
        type: actions.GET_ALL_DICTIONARIES_REQUEST,
    }),

    GET_ALL_ROLES_REQUEST: 'GET_ALL_ROLES_REQUEST',
    GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
    GET_ALL_ROLES_FAILED: 'GET_ALL_ROLES_FAILED',
    getAllRoles: () => ({
        type: actions.GET_ALL_ROLES_REQUEST,
    }),

    GET_ALL_COUNTRIES_REQUEST: 'GET_ALL_COUNTRIES_REQUEST',
    GET_ALL_COUNTRIES_SUCCESS: 'GET_ALL_COUNTRIES_SUCCESS',
    GET_ALL_COUNTRIES_FAILED: 'GET_ALL_COUNTRIES_FAILED',
    getAllCountries: () => ({
        type: actions.GET_ALL_COUNTRIES_REQUEST,
    }),

    GET_DICTIONARY_ITEMS_REQUEST: 'GET_DICTIONARY_ITEMS_REQUEST',
    GET_DICTIONARY_ITEMS_SUCCESS: 'GET_DICTIONARY_ITEMS_SUCCESS',
    GET_DICTIONARY_ITEMS_FAILED: 'GET_DICTIONARY_ITEMS_FAILED',
    getDictionaryItems: (dictionaryId, params) => ({
        type: actions.GET_DICTIONARY_ITEMS_REQUEST,
        dictionaryId: dictionaryId,
        params: params
    }),

    CREATE_DICTIONARY_ITEM_REQUEST: 'CREATE_DICTIONARY_ITEM_REQUEST',
    CREATE_DICTIONARY_ITEM_SUCCESS: 'CREATE_DICTIONARY_ITEM_SUCCESS',
    CREATE_DICTIONARY_ITEM_FAILED: 'CREATE_DICTIONARY_ITEM_FAILED',
    createDictionaryItem: (dictionaryId, data: DictionaryItem) => ({
        type: actions.CREATE_DICTIONARY_ITEM_REQUEST,
        dictionaryId: dictionaryId,
        data: data
    }),

    UPDATE_DICTIONARY_ITEM_REQUEST: 'UPDATE_DICTIONARY_ITEM_REQUEST',
    UPDATE_DICTIONARY_ITEM_SUCCESS: 'UPDATE_DICTIONARY_ITEM_SUCCESS',
    UPDATE_DICTIONARY_ITEM_FAILED: 'UPDATE_DICTIONARY_ITEM_FAILED',
    updateDictionaryItem: (dictionaryId, itemId, data: Role) => ({
        type: actions.UPDATE_DICTIONARY_ITEM_REQUEST,
        dictionaryId: dictionaryId,
        itemId: itemId,
        data: data
    }),

    DELETE_DICTIONARY_ITEM_REQUEST: 'DELETE_DICTIONARY_ITEM_REQUEST',
    DELETE_DICTIONARY_ITEM_SUCCESS: 'DELETE_DICTIONARY_ITEM_SUCCESS',
    DELETE_DICTIONARY_ITEM_FAILED: 'DELETE_DICTIONARY_ITEM_FAILED',
    deleteDictionaryItem: (dictionaryId, itemId) => ({
        type: actions.DELETE_DICTIONARY_ITEM_REQUEST,
        dictionaryId: dictionaryId,
        itemId: itemId
    })

};

export default actions;
