import actions from './actions';

const initState = {
    loading: false,
    error: null,
    status: null
};


const imageReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                status: null
            };

        case actions.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                status: 'refresh'
            };

        case actions.DELETE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                status: null
            };


        case actions.DOWNLOAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                status: null
            };

        case actions.DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                status: 'refresh'
            };

        case actions.DOWNLOAD_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                status: null
            };


        default:
            return state;
    }
};

export default imageReducer;