import axios from "axios/index";
import {root} from "../redux/api.config";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'password';

export default class PasswordAPI {

    static recoverPassword(data) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            data: data
        };
        /*return {
            response: {
                status: 200,
                data: {}
            }
        }*/
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static setPassword(hash, data) {
        const options = {
            url: `${root}/api/${resource}/${hash}`,
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            data: data
        };
        /*return {
            response: {
                status: 200,
                data: {}
            }
        }*/
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

}