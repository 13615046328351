import React, {Component} from "react";
import {Form, Input, Button, DatePicker, Select, Switch} from "antd";
import locale from 'antd/lib/date-picker/locale/pl_PL';
import 'moment/locale/pl';

const dateFormat = 'YYYY-MM-DD';
const {Option} = Select;

class UserForm extends Component {

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 18, offset: 6},
            },
        };

        const colStyle = {
            paddingLeft: '0.5em',
            paddingRight: '0.5em'
        }

        let roleOptions = [];
        if (this.props.roles) {
            roleOptions = this.props.roles.map((record) => {
                return (<Option key={record.id} value={record.id}>
                    {record.name}
                </Option>)
            });
        }

        let countryOptions = [];
        if (this.props.countries) {
            countryOptions = this.props.countries.map((record) => {
                return (<Option key={record.id} value={record.id}>
                    {record.name}
                </Option>)
            });
        }

        return <Form {...formItemLayout} onSubmit={this.props.handleSave}>

            <legend>Dane ogólne</legend>

            <Form.Item label="Nazwa użytkownika">
                {getFieldDecorator('username', {
                    rules: [
                        {
                            required: true,
                            message: 'Wpisz nazwę użytkownika',
                        },
                    ],
                })(<Input/>)}
            </Form.Item>

            <Form.Item label="Imię">
                {getFieldDecorator('firstname', {
                    rules: [
                        {
                            required: true,
                            message: 'Wpisz imię',
                        },
                    ],
                })(<Input/>)}
            </Form.Item>

            <Form.Item label="Nazwisko">
                {getFieldDecorator('surname', {
                    rules: [
                        {
                            required: true,
                            message: 'Wpisz nazwisko',
                        },
                    ],
                })(<Input/>)}
            </Form.Item>

            <Form.Item label="Czy użytkownik jest aktywny?">
                {getFieldDecorator('active', {
                    initialValue: this.props.data.active ? this.props.data.active : false,
                    valuePropName: 'checked',
                })(
                    <Switch checkedChildren="tak"
                            unCheckedChildren="nie"/>
                )}
            </Form.Item>

            <legend>Kontakt</legend>

            <Form.Item label="E-mail">
                {getFieldDecorator('email', {
                    rules: [
                        {
                            type: 'email',
                            message: 'To nie jest poprawny adres e-mail',
                        },
                        {
                            required: true,
                            message: 'Wpisz adres e-mail',
                        },
                    ],
                })(<Input/>)}
            </Form.Item>

            <Form.Item label="Telefon">
                {getFieldDecorator('phone', {})(<Input/>)}
            </Form.Item>

            <legend>Role</legend>

            <Form.Item {...formItemLayoutWithOutLabel}>
                {getFieldDecorator('role_ids', {})(
                    <Select mode="multiple"
                            style={{width: '100%'}}
                            placeholder="Wybierz role"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                        {roleOptions}
                    </Select>)}
            </Form.Item>


            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="primary"
                        icon="save"
                        title="Zapisz"
                        htmlType="submit">
                    Zapisz
                </Button>
                <Button type="secondary"
                        icon="close"
                        title="Anuluj"
                        onClick={this.props.handleCancel}
                        style={{marginLeft: 8}}>
                    Anuluj
                </Button>
            </Form.Item>

        </Form>;
    }
}

export const WrappedUserForm = Form.create({
    //ustawia wartości w polach formularza
    mapPropsToFields(props) {
        let fields = {};

        for (const i in props.data) {
            fields[i] = Form.createFormField({
                value: props.data[i],
            })
        }
        return fields;
    }
})(UserForm);
