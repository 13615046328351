import {takeEvery, put, call, select} from 'redux-saga/effects';
import actions from './actions';
import ProductAPI from '../../api/product';
import {Product} from "../../models/product";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";
import DeviceModelAPI from "../../api/device-model";
import {prUrl} from "../../helpers/PrecisionRoller";
import ManufacturerAPI from "../../api/manufacturer";
import {displayAsPdf, downloadAsCsv} from "../../helpers/Utility";

let ids = [];

function* getProducts(payload) {
    let e = yield call(ProductAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_PRODUCTS_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Product(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_PRODUCTS_FAILED,
            error: result
        });
    }
}

function* getProduct(payload) {
    let e = yield call(ProductAPI.getOne, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_PRODUCT_SUCCESS,
            data: new Product(e.response.data)
        });
    } else {
        yield put({
            type: actions.GET_PRODUCT_FAILED,
            error: result
        });
    }
}

function* createProduct(payload) {
    let e = yield call(ProductAPI.create, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Products);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_PRODUCTS_REQUEST,
            params: state
        });
        yield put({
            type: actions.CREATE_PRODUCT_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.CREATE_PRODUCT_FAILED,
            error: result
        });
    }
}

function* updateProduct(payload) {
    let e = yield call(ProductAPI.update, payload.id, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Products);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_PRODUCTS_REQUEST,
            params: state
        });
        yield put({
            type: actions.UPDATE_PRODUCT_SUCCESS,
            data: e.response
        });
        payload.history.push('/store/products');

    } else {
        yield put({
            type: actions.UPDATE_PRODUCT_FAILED,
            error: result
        });
    }
}

function* deleteProduct(payload) {
    let e = yield call(ProductAPI.delete, payload.id);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Products);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_PRODUCTS_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_PRODUCT_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_PRODUCT_FAILED,
            error: result
        });
    }
}

function parseProductData(htmlDoc, modelId) {
    const parser = new DOMParser();
    const data = {};
    const id = htmlDoc.querySelector("input.new_cartadd.yellow_button");
    if (id) {
        const productId = id.getAttribute('data-catalogid');
        if (ids.indexOf(productId) === -1) {
            data.product = {};
            data.product.id = productId;

            const name = htmlDoc.querySelector("h1[itemprop='name']");
            if (name) {
                data.product.name = name.innerText.replace(/(?:\r\n|\r|\n)/g, '').trim();
            }

            const price = htmlDoc.querySelector("span[itemprop='price']");
            if (price) {
                data.product.price = price.innerText.replace(/(?:\r\n|\r|\n)/g, '').trim();
            }

            const photo = htmlDoc.querySelector("a.fancyimg");
            if (photo) {
                data.product.photo = prUrl + photo.getAttribute('href');
            }

            const category = htmlDoc.querySelector("input[name='c']");
            if (category) {
                data.product.category_id = category.getAttribute('value');
            }

            const mfr_part_multi = htmlDoc.querySelectorAll("div[id='oemnumbers'] ul li");
            if (mfr_part_multi.length > 0) {
                const m = [];
                for (var i = 0; i < mfr_part_multi.length; i++) {
                    m.push(mfr_part_multi[i].innerText);
                }
                data.product.mfr_part = m.join(';');

            } else {
                const mfr_part = htmlDoc.querySelector("table[id='partdetails']");
                const t = mfr_part.innerText;
                const start = t.indexOf('Mfr Part #:') + 11;
                const end = t.indexOf('PR Order #:', start);
                data.product.mfr_part = t.substr(start, end - start).replace(/(?:\r\n|\r|\n)/g, '').trim().replace('\t\t\t', ' ');
            }

            const spec = htmlDoc.querySelectorAll("div[id='specs'] ul li");
            if (spec.length > 0) {
                data.product.params = [];
                for (var i = 0; i < spec.length; i++) {
                    const htmlLi = parser.parseFromString(spec[i].innerHTML, 'text/html');
                    const paramName = htmlLi.querySelector("b").innerText;
                    const start = spec[i].innerHTML.indexOf('>:') + 2;
                    let end = spec[i].innerHTML.indexOf('<div id');
                    if (end === false) {
                        end = spec[i].innerHTML.indexOf('</li>');
                    }
                    const paramValue = spec[i].innerHTML.substr(start, end - start).replace('&#13;', '').trim();
                    if (paramName && paramValue) {
                        data.product.params.push({
                            product_id: data.product.id,
                            param_name: paramName,
                            param_value: paramValue
                        });
                    }
                }
            }
            ids.push(data.product.id);
        }
        data.compatibility = {model_id: modelId, product_id: productId};
    }
    return data;
}

function* getProductsByDeviceModel(model) {
    let url = 'https://www.precisionroller.com/all-supplies-and-parts-for-' + model.manufacturer_seo_name + '-' + model.seo_name + '/products.html';
    //console.log(url);
    const compatibilities = [];
    const products = [];
    const e2 = yield call(ProductAPI.getProductsPage, url);
    if (e2.response && e2.response.data) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(e2.response.data, 'text/html');
        const nodes = htmlDoc.querySelectorAll("a.title_link");
        //console.log(nodes.length);
        if (nodes.length > 0) {
            for (var j = 0; j < nodes.length; j++) {
                //console.log(nodes[j]);
                if (nodes[j].hasAttribute('href')) {
                    const href = nodes[j].getAttribute('href');
                    const e3 = yield call(ProductAPI.getProductsPage, href);
                    if (e3.response.data) {
                        const htmlDetailsDoc = parser.parseFromString(e3.response.data, 'text/html');
                        const data = parseProductData(htmlDetailsDoc, model.id);
                        if (data.product) {
                            data.product.url = href;
                            products.push(data.product);
                        }
                        if (data.compatibility) {
                            compatibilities.push(data.compatibility);
                        }
                    }
                }
            }
        } else {
            const data = parseProductData(htmlDoc, model.id);
            if (data.product) {
                data.product.url = url;
                products.push(data.product);
            }
            if (data.compatibility) {
                compatibilities.push(data.compatibility);
            }
        }
    }
    return {products: products, compatibilities: compatibilities};
}

function* importProducts(payload) {
    let e1 = yield call(DeviceModelAPI.getList, {
        recordsPerPage: 100000,
        page: 1,
        withoutProducts: 1
    });
    let result1 = checkError(e1);
    if (result1.success) {
        //pobranie stanu
        let state = yield select((state) => state.Products);

        const models = e1.response.data.items;
        const total = models.length;

        let success = true;
        let error = null;
        for (var i in models) {
            const data = yield call(getProductsByDeviceModel, models[i]);
            //const data = {model_id: models[i].id, products: products};
            //console.log('Model', models[i]);
            //console.log(data);

            let e = yield call(ProductAPI.importProducts, data);

            yield put({
                type: actions.SET_PROGRESS,
                percent: Math.round(100 * (parseInt(i) + 1) / total)
            });

            let result = checkError(e);
            success = success && result.success;
            if (!success) {
                error = result;
            }
        }
        ids = [];

        if (success) {
            //odświeżenie widoku poprzez ponowne pobranie danych
            yield put({
                type: actions.GET_PRODUCTS_REQUEST,
                params: state
            });
            yield put({
                type: actions.IMPORT_PRODUCTS_SUCCESS,

            });
            //messages: e.response.data.messages
        } else {
            yield put({
                type: actions.IMPORT_PRODUCTS_FAILED,
                error: error
            });
        }
    } else {
        yield put({
            type: actions.IMPORT_PRODUCTS_FAILED,
            error: result1
        });
    }
}

function* getProductsPdf(payload) {
    let e = yield call(ProductAPI.getListPdf, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        displayAsPdf(e.response.data, 'products.pdf');

        yield put({
            type: actions.GET_PRODUCTS_PDF_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_PRODUCTS_PDF_FAILED,
            error: result
        });
    }
}

function* getProductsCsv(payload) {
    let e = yield call(ProductAPI.getListCsv, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        downloadAsCsv(e.response.data, 'products.csv');

        yield put({
            type: actions.GET_PRODUCTS_CSV_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_PRODUCTS_CSV_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_PRODUCTS_REQUEST', getProducts);
    yield takeEvery('GET_PRODUCT_REQUEST', getProduct);
    yield takeEvery('CREATE_PRODUCT_REQUEST', createProduct);
    yield takeEvery('UPDATE_PRODUCT_REQUEST', updateProduct);
    yield takeEvery('DELETE_PRODUCT_REQUEST', deleteProduct);
    yield takeEvery(actions.IMPORT_PRODUCTS_REQUEST, importProducts);
    yield takeEvery(actions.GET_PRODUCTS_PDF_REQUEST, getProductsPdf);
    yield takeEvery(actions.GET_PRODUCTS_CSV_REQUEST, getProductsCsv);
}
