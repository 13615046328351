import {takeEvery, put, call, select} from 'redux-saga/effects';
import actions from './actions';
import RoleAPI from '../../api/role';
import {Role} from "../../models/role";
import {checkError} from "../api.errors";
import {Dictionary, DictionaryItem} from "../../models/dictionary";
import DictionaryAPI from "../../api/dictionary";

import {getRequestFromState} from "../../models/request";

function* getDictionaries(payload) {
    console.log(payload);
    let e = yield call(DictionaryAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_DICTIONARIES_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Dictionary(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_DICTIONARIES_FAILED,
            error: result
        });
    }
}

function* getAllRoles(payload) {
    let e = yield call(RoleAPI.getList, {});

    let result = checkError(e);

    if (result.success) {
        const r = [];
        e.response.data.items.forEach((record) => {
            r[record.id] = new Role(record);
        })
        yield put({
            type: actions.GET_ALL_ROLES_SUCCESS,
            data: r,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_ALL_ROLES_FAILED,
            error: result
        });
    }
}

function* getAllDictionaries(payload) {
    let e1 = yield call(RoleAPI.getList, {});

    let result1 = checkError(e1);

    if (result1.success) {
        const roles = [];
        e1.response.data.items.forEach((record) => {
            roles[record.id] = new Role(record);
        });

        let e4 = yield call(DictionaryAPI.getItems, 0, {sort: {name: 'asc'}});

        let result4 = checkError(e4);

        if (result4.success) {
            const all = {};
            e4.response.data.items.forEach((record) => {
                if (!(record.dictionary_id in all)) {
                    all[record.dictionary_id] = [];
                }
                all[record.dictionary_id].push(new DictionaryItem(record));
            });

            yield put({
                type: actions.GET_ALL_DICTIONARIES_SUCCESS,
                roles: {
                    data: roles,
                    total: e1.response.data.total
                },
                all: all
            });
        }

    } else {
        yield put({
            type: actions.GET_ALL_DICTIONARIES_FAILED,
            error: result1
        });
    }
}

function* getDictionaryItems(payload) {
    let e = yield call(DictionaryAPI.getItems, payload.dictionaryId, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_DICTIONARY_ITEMS_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new DictionaryItem(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total,
            dictionaryId: payload.dictionaryId
        });
    } else {
        yield put({
            type: actions.GET_DICTIONARY_ITEMS_FAILED,
            error: result
        });
    }
}

function* createDictionaryItem(payload) {
    let e = yield call(DictionaryAPI.createItem, payload.dictionaryId, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Dictionaries);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_DICTIONARY_ITEMS_REQUEST,
            params: state
        });
        yield put({
            type: actions.CREATE_DICTIONARY_ITEM_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.CREATE_DICTIONARY_ITEM_FAILED,
            error: result
        });
    }
}

function* updateDictionaryItem(payload) {
    let e = yield call(DictionaryAPI.updateItem, payload.dictionaryId, payload.itemId, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Dictionaries);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_DICTIONARY_ITEMS_REQUEST,
            params: state
        });
        yield put({
            type: actions.UPDATE_DICTIONARY_ITEM_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.UPDATE_DICTIONARY_ITEM_FAILED,
            error: result
        });
    }
}

function* deleteDictionaryItem(payload) {
    let e = yield call(DictionaryAPI.deleteItem, payload.dictionaryId, payload.itemId);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Dictionaries);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_DICTIONARY_ITEMS_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_DICTIONARY_ITEM_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_DICTIONARY_ITEM_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_DICTIONARIES_REQUEST', getDictionaries);
    yield takeEvery('GET_ALL_ROLES_REQUEST', getAllRoles);
    yield takeEvery('GET_ALL_DICTIONARIES_REQUEST', getAllDictionaries)
    yield takeEvery('GET_DICTIONARY_ITEMS_REQUEST', getDictionaryItems);
    yield takeEvery('CREATE_DICTIONARY_ITEM_REQUEST', createDictionaryItem);
    yield takeEvery('UPDATE_DICTIONARY_ITEM_REQUEST', updateDictionaryItem);
    yield takeEvery('DELETE_DICTIONARY_ITEM_REQUEST', deleteDictionaryItem);
}
