import React, {Component} from "react";
import {Button, Form, Modal, Input} from 'antd';

const EditDictionaryItemModal = Form.create({
    //ustawia wartości w polach formularza
    mapPropsToFields(props) {
        let fields = {};
        for (const i in props.data) {
            if (i == 'name' || i == 'info' || i == 'icon') {
                fields[i] = Form.createFormField({
                    value: props.data[i],
                })
            }
        }
        return fields;
    }
})
(
    class extends Component {

        render() {
            const {visible, onCancel, onSave, form, title} = this.props;
            const {getFieldDecorator} = form;

            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 5},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 19},
                },
            };

            /*const formItemLayoutWithOutLabel = {
                wrapperCol: {
                    xs: {span: 24, offset: 0},
                    sm: {span: 20, offset: 4},
                },
            };*/
            return (
                <Modal
                    title={title}
                    visible={visible}
                    style={{top: 20}}
                    width={800}
                    onOk={onSave}
                    onCancel={onCancel}
                    footer={[
                        <Button icon="close" key="back" onClick={onCancel}>
                            Anuluj
                        </Button>,
                        <Button icon="save" key="submit" type="primary" onClick={onSave}>
                            Zapisz
                        </Button>,
                    ]}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label="Nazwa">
                            {getFieldDecorator('name', {
                                rules: [{required: true, message: 'Wprowadź unikalną nazwę elementu'}],
                            })(
                                <Input/>
                            )}
                        </Form.Item>

                        <Form.Item label="Opis">
                            {getFieldDecorator('info')(
                                <Input.TextArea/>
                            )}
                        </Form.Item>

                        <Form.Item label="Ikona">
                            {getFieldDecorator('icon')(
                                <Input/>
                            )}
                        </Form.Item>

                    </Form>
                </Modal>
            );
        }
    });

export default EditDictionaryItemModal;