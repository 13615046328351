import {Model} from "./model";

export class Dictionary extends Model {

    id: number;
    name: string;
    info: string;

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }
}

export class DictionaryItem extends Model {

    dictionary_id: number;
    item_id: number;
    name: string;
    info: string;
    icon: string;
    insert_time: string;
    update_time: string;

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }
}