import React, {Component} from 'react';
import {Alert} from "antd";

export default class InfoAlert extends Component {
    render() {
        if (this.props.message) {
            let description = this.props.message;
            return (
                <Alert
                    message="Komunikat"
                    description={description}
                    type="info"
                    closable
                    onClose={this.props.onClose}
                    style={{width: '100%', margin: '1em 0'}}
                />
            );
        }
        return null;
    }
}
