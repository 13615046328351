import {takeEvery, put, call} from 'redux-saga/effects';
import actions from './actions';
import ManufacturerAPI from '../../api/manufacturer';
import {Manufacturer} from "../../models/manufacturer";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";

function* getManufacturerList(payload) {
    let e = yield call(ManufacturerAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_MANUFACTURER_LIST_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Manufacturer(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_MANUFACTURER_LIST_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_MANUFACTURER_LIST_REQUEST', getManufacturerList);
}
