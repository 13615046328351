import {Product} from "../../models/product";

const actions = {
    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILED: 'GET_PRODUCTS_FAILED',
    getProducts: (params) => ({
        type: actions.GET_PRODUCTS_REQUEST,
        params: params
    }),

    GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
    GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
    GET_PRODUCT_FAILED: 'GET_PRODUCT_FAILED',
    getProduct: (id) => ({
        type: actions.GET_PRODUCT_REQUEST,
        id: id
    }),

    CLEAR_PRODUCT: 'CLEAR_PRODUCT',
    clearProduct: () => ({
        type: actions.CLEAR_PRODUCT
    }),

    CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
    CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
    CREATE_PRODUCT_FAILED: 'CREATE_PRODUCT_FAILED',
    createProduct: (data: Product) => ({
        type: actions.CREATE_PRODUCT_REQUEST,
        data: data
    }),

    UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
    UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED',
    updateProduct: (id, data: Product, history) => ({
        type: actions.UPDATE_PRODUCT_REQUEST,
        id: id,
        data: data,
        history: history
    }),

    DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
    DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_FAILED: 'DELETE_PRODUCT_FAILED',
    deleteProduct: (id) => ({
        type: actions.DELETE_PRODUCT_REQUEST,
        id: id
    }),

    IMPORT_PRODUCTS_REQUEST: 'IMPORT_PRODUCTS_REQUEST',
    IMPORT_PRODUCTS_SUCCESS: 'IMPORT_PRODUCTS_SUCCESS',
    IMPORT_PRODUCTS_FAILED: 'IMPORT_PRODUCTS_FAILED',
    importProducts: () => ({
        type: actions.IMPORT_PRODUCTS_REQUEST,
    }),

    SET_PROGRESS: 'SET_PROGRESS',
    setProgress: (percent) => ({
        type: actions.SET_PROGRESS,
        percent: percent
    }),

    GET_PRODUCTS_PDF_REQUEST: 'GET_PRODUCTS_PDF_REQUEST',
    GET_PRODUCTS_PDF_SUCCESS: 'GET_PRODUCTS_PDF_SUCCESS',
    GET_PRODUCTS_PDF_FAILED: 'GET_PRODUCTS_PDF_FAILED',
    getProductsPdf: (params) => ({
        type: actions.GET_PRODUCTS_PDF_REQUEST,
        params: params
    }),

    GET_PRODUCTS_CSV_REQUEST: 'GET_PRODUCTS_CSV_REQUEST',
    GET_PRODUCTS_CSV_SUCCESS: 'GET_PRODUCTS_CSV_SUCCESS',
    GET_PRODUCTS_CSV_FAILED: 'GET_PRODUCTS_CSV_FAILED',
    getProductsCsv: (params) => ({
        type: actions.GET_PRODUCTS_CSV_REQUEST,
        params: params
    }),
}

export default actions;
