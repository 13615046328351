import React, {Component} from "react";
import {Button, Icon, Input} from "antd";

export class Container extends Component {

    //nazwa zasobu zgodna z reduxem (mapStateToProps)
    resource = '';

    //obiekt modelu danych
    model = {};

    //funkcja zwracająca listę rekordów
    getResource = (params) => {
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Szukaj po polu ${dataIndex}`}
                    value={selectedKeys}
                    onChange={e => setSelectedKeys(e.target.value ? e.target.value : null)}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Szukaj
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        /*onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),*/
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },

    });

    /**
     * Zwraca napis wyświetlający liczbę wszystkich aktualnych rekordów
     * @param total - liczba wszystkich aktualnych rekordów
     * @returns {string}
     */
    showTotalItemsDialog = (total) => {
        return 'Znaleziono rekordów: ' + total;
    };

    /**
     * Zwraca referencję do formularza edycji
     * @param formRef
     */
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    /**
     * Obsługa paginacji, filtrowania i sortowania danych tabeli
     * @param pagination
     * @param filters
     * @param sorter
     */
    handleTableChange = (pagination, filters, sorter) => {
        const r = this.props[this.resource];
        //ponizsza funkcja dodaje do argumentu 'filters' brakujace mu filtry ze stanu 'p.filters'
        //moim zdaniem lepiej by było gdyby poniższy kod był zawarty w definicji kolumn
        /*if(Object.keys(r.filters)!== 0){
            Object.keys(r.filters).forEach(
                (key)=>{
                    //if (filters[key][0] === undefined)
                     //filters[key] = [p.filters[key]]

                    const modelProp = key;
                    //console.log(modelProp); //patientName

                    if (filters[modelProp][0] === undefined)
                        filters[modelProp] = [r.filters[key]]
                }
            );
        }*/

        r.sort = {};
        if (sorter.field && sorter.order) {
            r.sort[sorter.field] = sorter.order;
        }

        if (pagination) {
            r.pagination = pagination;
        }

        r.filters = {};
        for (let i in filters) {
            if (filters[i]) {
                r.filters[i] = filters[i];
            }
        }

        this.getResource(r);
    };

    /**
     * Filtruje dane po wielu polach
     * przechodząc na stronę nr 1
     * @param value
     */
    filterFullText = (value) => {
        let p = this.props[this.resource];
        p.fullText = null;
        //console.log(value);
        if (value) {
            p.fullText = value;
            p.pagination.current = 1;
        }
        //console.log(p);
        this.getResource(p);
    }

    createMarkup(html) {
        return {__html: html};
    }

    refresh = () => {
        this.getResource(this.props[this.resource]);
    }

}
