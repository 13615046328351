import actions from './actions';

const initState = {
    sidebar: {
        collapsed: false,
    }
};

const settingsReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_SIDEBAR_COLLAPSED:
            let sidebar = {collapsed: action.collapsed};
            return {sidebar: sidebar};

        default:
            return state;
    }
};

export default settingsReducer;