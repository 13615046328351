import React, {Component} from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Spin} from "antd";
import {connect} from "react-redux";
import actions from "../../redux/role/actions";
import {WrappedRoleForm} from "./RoleForm";
import {Role} from "../../models/role";
import CheckError from "../../components/checkError";

const {Content} = Layout;
const {getRole, clearRole, createRole, updateRole} = actions;

export class RoleContainer extends Component {

    componentDidMount() {
        //console.log('componentDidMount');
        if (this.props.match.params.id) {
            this.props.getRole(this.props.match.params.id);
        } else {
            this.props.clearRole();
        }
    };

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (nextProps.roles.status === 'refresh') {
            //console.log('componentWillReceiveProps');
            nextProps.history.push('/users/roles');
        }
    }

    /**
     * Zwraca referencję do formularza edycji
     * @param formRef
     */
    saveEditFormRef = (formRef) => {
        this.editFormRef = formRef;
    }

    /**
     * Zapisuje dane
     * @param e
     */
    handleSave = e => {
        e.preventDefault();
        const form = this.editFormRef.props.form;
        form.validateFieldsAndScroll((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }

            //wysyłka danych do serwera
            const r = new Role();
            let data = r.getData(values);
            console.log('Received values of form: ', data);

            if (this.props.match.params.id) {
                this.props.updateRole(this.props.match.params.id, data);
            } else {
                this.props.createRole(data);
            }

            //wyczyszczenie formularza
            form.resetFields();
        });
    };

    /**
     * Anuluje edycję
     */
    handleCancel = () => {
        const form = this.editFormRef.props.form;
        //wyczyszczenie formularza
        form.resetFields();

        this.props.history.push('/users/roles');
    }

    render() {
        const role = this.props.roles.data;
        let title = this.props.match.params.id ? 'Rola ' + role.name : 'Nowa rola';

        return <Layout style={{padding: '0 24px 24px'}}>
            <PageHeader>{title}</PageHeader>
            <Content style={{
                background: '#fff',
                padding: 24,
                margin: 0,
            }}>
                <CheckError error={this.props.roles.error} history={this.props.history}/>
                <Spin spinning={this.props.roles.loading}>
                    <WrappedRoleForm
                        wrappedComponentRef={this.saveEditFormRef}
                        handleSave={this.handleSave}
                        handleCancel={this.handleCancel}
                        id={this.props.match.params.id}
                        data={role}
                    />
                </Spin>

            </Content>
        </Layout>;
    }
}

const mapStateToProps = (state) => ({
    roles: state.Roles
});

export default connect(mapStateToProps, {
    getRole,
    clearRole,
    createRole,
    updateRole
})(RoleContainer);