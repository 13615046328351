import {takeEvery, put, call, select} from 'redux-saga/effects';
import actions from './actions';
import ManufacturerAPI from '../../api/manufacturer';
import {Manufacturer} from "../../models/manufacturer";
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";
import {displayAsPdf, downloadAsCsv} from "../../helpers/Utility";

function* getManufacturers(payload) {
    let e = yield call(ManufacturerAPI.getList, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_MANUFACTURERS_SUCCESS,
            data: e.response.data.items.map((record) => {
                return new Manufacturer(record);
            }),
            sort: payload.params.sort,
            filters: payload.params.filters,
            total: e.response.data.total
        });
    } else {
        yield put({
            type: actions.GET_MANUFACTURERS_FAILED,
            error: result
        });
    }
}

function* getManufacturer(payload) {
    let e = yield call(ManufacturerAPI.getOne, payload.id);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_MANUFACTURER_SUCCESS,
            data: new Manufacturer(e.response.data)
        });
    } else {
        yield put({
            type: actions.GET_MANUFACTURER_FAILED,
            error: result
        });
    }
}

function* createManufacturer(payload) {
    let e = yield call(ManufacturerAPI.create, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Manufacturers);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_MANUFACTURERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.CREATE_MANUFACTURER_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.CREATE_MANUFACTURER_FAILED,
            error: result
        });
    }
}

function* updateManufacturer(payload) {
    let e = yield call(ManufacturerAPI.update, payload.id, payload.data);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Manufacturers);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_MANUFACTURERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.UPDATE_MANUFACTURER_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.UPDATE_MANUFACTURER_FAILED,
            error: result
        });
    }
}

function* deleteManufacturer(payload) {
    let e = yield call(ManufacturerAPI.delete, payload.id);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Manufacturers);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_MANUFACTURERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.DELETE_MANUFACTURER_SUCCESS,
            data: e.response
        });
    } else {
        yield put({
            type: actions.DELETE_MANUFACTURER_FAILED,
            error: result
        });
    }
}

function* importManufacturers(payload) {
    let e = yield call(ManufacturerAPI.importManufacturers);

    let result = checkError(e);

    if (result.success) {
        //pobranie stanu
        let state = yield select((state) => state.Manufacturers);
        //odświeżenie widoku poprzez ponowne pobranie danych
        yield put({
            type: actions.GET_MANUFACTURERS_REQUEST,
            params: state
        });
        yield put({
            type: actions.IMPORT_MANUFACTURERS_SUCCESS,
            messages: e.response.data.messages
        });
    } else {
        yield put({
            type: actions.IMPORT_MANUFACTURERS_FAILED,
            error: result
        });
    }
}

function* getManufacturersPdf(payload) {
    let e = yield call(ManufacturerAPI.getListPdf, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        displayAsPdf(e.response.data, 'manufacturers.pdf');

        yield put({
            type: actions.GET_MANUFACTURERS_PDF_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_MANUFACTURERS_PDF_FAILED,
            error: result
        });
    }
}

function* getManufacturersCsv(payload) {
    let e = yield call(ManufacturerAPI.getListCsv, getRequestFromState(payload.params));

    let result = checkError(e);

    if (result.success && e.response.data) {
        downloadAsCsv(e.response.data, 'manufacturers.csv');

        yield put({
            type: actions.GET_MANUFACTURERS_CSV_SUCCESS
        });
    } else {
        yield put({
            type: actions.GET_MANUFACTURERS_CSV_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_MANUFACTURERS_REQUEST', getManufacturers);
    yield takeEvery('GET_MANUFACTURER_REQUEST', getManufacturer);
    yield takeEvery('CREATE_MANUFACTURER_REQUEST', createManufacturer);
    yield takeEvery('UPDATE_MANUFACTURER_REQUEST', updateManufacturer);
    yield takeEvery('DELETE_MANUFACTURER_REQUEST', deleteManufacturer);
    yield takeEvery(actions.IMPORT_MANUFACTURERS_REQUEST, importManufacturers);
    yield takeEvery(actions.GET_MANUFACTURERS_PDF_REQUEST, getManufacturersPdf);
    yield takeEvery(actions.GET_MANUFACTURERS_CSV_REQUEST, getManufacturersCsv);
}
