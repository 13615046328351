import React, {Component} from "react";
import WrappedNormalLoginForm from "./LoginForm";
import {connect} from "react-redux";
import actions from "../../redux/auth/actions";
import CheckError from "../../components/checkError";

const {login, logout} = actions;

export class LoginContainer extends Component {

    constructor(props) {
        super(props);

        // reset login status
        this.props.logout();
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (nextProps.auth.loggedIn) {
            nextProps.history.push('/');
        }
    }

    /**
     * Zwraca referencję do formularza
     * @param formRef
     */
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);

            this.props.login(values.username, values.password);

            //wyczyszczenie formularza
            //form.resetFields();
        });
    }

    render() {
        return (<div style={{maxWidth: '350px'}}>
            <CheckError error={this.props.auth.error}/>
            <WrappedNormalLoginForm
                wrappedComponentRef={this.saveFormRef}
                handleSubmit={this.handleSubmit}
            />
        </div>);
    }
}

const mapStateToProps = (state) => ({
    auth: state.Auth,
});

export default connect(mapStateToProps, {
    login, logout
})(LoginContainer);