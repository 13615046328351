export function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function downloadFile(url, fileName) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    //document.body.appendChild(link);
    link.click();
}

export function displayAsPdf(data, fileName) {
    //Create a Blob from the PDF Stream
    const file = new Blob(
        [data],
        {type: 'application/pdf'});
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
}

export function downloadAsCsv(data, fileName) {
    //Create a Blob from the PDF Stream
    const file = new Blob(
        [data],
        {type: 'text/csv'});
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    downloadFile(fileURL, fileName);
}
