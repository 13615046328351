import React, {Component} from "react";
import LoginLayoutRoute from "./LoginLayout";
import DashboardLayoutRoute from "./DashboardLayout";
import {connect} from "react-redux";
import actions from "./redux/dictionary/actions";
import {Spin} from "antd";

import HomeContainer from "./containers/home/HomeContainer";
import UsersContainer from "./containers/users/UsersContainer";
import UserContainer from "./containers/users/UserContainer";
import RolesContainer from "./containers/roles/RolesContainer";
import SessionsContainer from "./containers/sessions/SessionsContainer";
import LoginContainer from "./containers/login/LoginContainer";
import RegisterContainer from "./containers/login/RegisterContainer";
import RecoverPasswordContainer from "./containers/login/RecoverPasswordContainer";
import SetPasswordContainer from "./containers/login/SetPasswordContainer";
import RoleContainer from "./containers/roles/RoleContainer";
import DictionariesContainer from "./containers/dictionaries/DictionariesContainer";
import ManufacturersContainer from "./containers/manufacturers/ManufacturersContainer";
import DeviceModelsContainer from "./containers/device-models/DeviceModelsContainer";
import ProductsContainer from "./containers/products/ProductsContainer";
import ProductContainer from "./containers/products/ProductContainer";
import CategoriesContainer from "./containers/categories/CategoriesContainer";

const {getAllDictionaries} = actions;


const routes = [
    {
        path: "/",
        exact: true,
        component: HomeContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/users/users",
        //component: asyncComponent(() => import("./containers/users/UsersContainer"))
        component: UsersContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/users/users/add",
        component: UserContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/users/users/edit/:id",
        component: UserContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/users/roles",
        component: RolesContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/users/roles/add",
        component: RoleContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/users/roles/edit/:id",
        component: RoleContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/users/sessions",
        component: SessionsContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/lists/dictionaries",
        component: DictionariesContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/login",
        component: LoginContainer,
        layout: LoginLayoutRoute
    },
    {
        path: "/register",
        component: RegisterContainer,
        layout: LoginLayoutRoute
    },
    {
        path: "/recover-password",
        component: RecoverPasswordContainer,
        layout: LoginLayoutRoute
    },
    {
        path: "/set-password/:hash",
        component: SetPasswordContainer,
        layout: LoginLayoutRoute
    },
    {
        path: "/store/manufacturers",
        //component: asyncComponent(() => import("./containers/users/UsersContainer"))
        component: ManufacturersContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/store/models",
        component: DeviceModelsContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/store/products",
        component: ProductsContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/store/products/edit/:id",
        component: ProductContainer,
        layout: DashboardLayoutRoute
    },
    {
        path: "/store/categories",
        component: CategoriesContainer,
        layout: DashboardLayoutRoute
    },
];

export class AppRouter extends Component {
    constructor(props) {
        super(props);
        //console.log(this.props);
        this.state = {
            loading: true
        }
        props.getAllDictionaries();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!nextProps.dictionary.loading) {
            this.setState({loading: false});
        }
    }

    render() {
        const {style} = this.props;

        return (
            <div style={{height: '100%'}}>
                {
                    this.state.loading ?
                        <div>
                            <h1 style={{textAlign: 'center', padding: '2em', height: '100%'}}>Uruchamianie
                                aplikacji</h1>
                            <Spin spinning={this.state.loading}>
                                <div></div>
                            </Spin>
                        </div>
                        :
                        routes.map(route => {
                            const {path, exact, layout, ...otherProps} = route;
                            let LayoutRoute = layout;
                            return (
                                <LayoutRoute
                                    exact={exact === false ? false : true}
                                    key={path}
                                    path={path}
                                    {...otherProps}
                                />
                            );
                        })
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    dictionary: state.Dictionaries
});

export default connect(mapStateToProps, {
    getAllDictionaries
})(AppRouter);
