import {Model} from "./model";

export class Role extends Model {

    id: number;
    name: string;
    description: string;
    icon: string;
    color: string;
    insert_time: string;
    update_time: string;

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }
}