import actions from './actions';
import {User} from "../../models/user";

const initState = {
    pagination: {
        pageSize: 10,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    items: [],
    data: new User({role_ids: ["3"]}),
    error: null
};


const userReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_USERS_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_USERS_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {
                ...state,
                loading: false,
                items: action.data,
                pagination: pagination,
                sort: action.sort,
                filters: action.filters
            };

        case actions.GET_USERS_FAILED:
            return {...state, loading: false, items: [], error: action.error};

        case actions.GET_USER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_USER_SUCCESS:
            return {...state, loading: false, data: action.data};

        case actions.GET_USER_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.CLEAR_USER:
            return {...state, data: new User({role_ids: ["3"]})};

        case actions.CREATE_USER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.CREATE_USER_SUCCESS:
            return {...state, loading: false};

        case actions.CREATE_USER_FAILED:
            return {...state, loading: false};

        case actions.UPDATE_USER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.UPDATE_USER_SUCCESS:
            return {...state, loading: false};

        case actions.UPDATE_USER_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.DELETE_USER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_USER_SUCCESS:
            return {...state, loading: false};

        case actions.DELETE_USER_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.IMPORT_PICTURE_REQUEST:
            return {...state, loading: true, error: null};

        case actions.IMPORT_PICTURE_SUCCESS:
            return {...state, loading: false};

        case actions.IMPORT_PICTURE_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.IMPORT_ID_REQUEST:
            return {...state, loading: true, error: null};

        case actions.IMPORT_ID_SUCCESS:
            return {...state, loading: false};

        case actions.IMPORT_ID_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default userReducer;
