import React, {Component} from "react";
import {Form, Input, Button, Select, Switch, InputNumber, Empty, Row, Col} from "antd";

const {Option} = Select;

class ProductForm extends Component {

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 18, offset: 6},
            },
        };

        let categoryOptions = [];
        if (this.props.categories) {
            categoryOptions = this.props.categories.map((record) => {
                return (<Option key={record.id} value={record.id}>
                    {record.name}
                </Option>)
            });
        }

        return <Form {...formItemLayout} onSubmit={this.props.handleSave}>

            <Row>
                <Col span={6}>
                    {this.props.data.photo ? <img style={{width: '100%'}} src={this.props.data.photo}/> : <Empty description={<span>Brak zdjęcia</span>}/>}
                </Col>

                <Col span={18}>
                    <Form.Item label="ID">
                        {getFieldDecorator('id', {
                            rules: [{required: true, message: 'Wprowadź id produktu'}],
                        })(
                            <Input/>
                        )}
                    </Form.Item>

                    <Form.Item label="Kategoria">
                        {getFieldDecorator('category_id', {
                            rules: [{required: true, message: 'Wybierz kategorię'}],
                        })(
                            <Select style={{width: '100%'}}
                                    showSearch
                                    placeholder="Wybierz kategorię"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                {categoryOptions}
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="Nazwa">
                        {getFieldDecorator('name', {
                            rules: [{required: true, message: 'Wprowadź nazwę produktu'}],
                        })(
                            <Input/>
                        )}
                    </Form.Item>

                    <Form.Item label="Cena">
                        {getFieldDecorator('price', {
                            rules: [{required: true, message: 'Wprowadź cenę produktu'}],
                        })(
                            <InputNumber/>
                        )}
                    </Form.Item>

                    <Form.Item label="Mfr Part">
                        {getFieldDecorator('mfr_part')(
                            <Input/>
                        )}
                    </Form.Item>

                    <Form.Item label="Url">
                        {getFieldDecorator('url')(
                            <Input/>
                        )}
                    </Form.Item>

                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button type="primary"
                                icon="save"
                                title="Zapisz"
                                htmlType="submit">
                            Zapisz
                        </Button>
                        <Button type="secondary"
                                icon="close"
                                title="Anuluj"
                                onClick={this.props.handleCancel}
                                style={{marginLeft: 8}}>
                            Anuluj
                        </Button>
                    </Form.Item>
                </Col>
            </Row>

        </Form>;
    }
}

export const WrappedProductForm = Form.create({
    //ustawia wartości w polach formularza
    mapPropsToFields(props) {
        let fields = {};

        for (const i in props.data) {
            fields[i] = Form.createFormField({
                value: props.data[i],
            })
        }
        return fields;
    }
})(ProductForm);
