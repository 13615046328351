import {Model} from "./model";

export class Category extends Model {

    id: number;
    name: string;

    map = {};

    constructor(data) {
        super();
        this.setData(data);
    }
}
