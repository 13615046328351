import axios from "axios/index";
import {root} from "../redux/api.config";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'image';

export default class ImageAPI {

    static deleteImage(subresource, id) {
        let params = {id: id};
        const options = {
            url: `${root}/api/${resource}/${subresource}`,
            method: 'delete',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static downloadImage(subresource, id, url) {
        let params = {url: url};
        const options = {
            url: `${root}/api/${resource}/${subresource}/${id}`,
            method: 'get',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

}