import {User} from "../../models/user";

const actions = {
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILED: 'GET_USERS_FAILED',

    getUsers: (params) => ({
        type: actions.GET_USERS_REQUEST,
        params: params
    }),

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILED: 'GET_USER_FAILED',

    getUser: (id) => ({
        type: actions.GET_USER_REQUEST,
        id: id
    }),

    CLEAR_USER: 'CLEAR_USER',

    clearUser: () => ({
        type: actions.CLEAR_USER
    }),

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILED: 'CREATE_USER_FAILED',

    createUser: (data: User) => ({
        type: actions.CREATE_USER_REQUEST,
        data: data
    }),

    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',

    updateUser: (id, data: User) => ({
        type: actions.UPDATE_USER_REQUEST,
        id: id,
        data: data
    }),

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILED: 'DELETE_USER_FAILED',

    deleteUser: (id) => ({
        type: actions.DELETE_USER_REQUEST,
        id: id
    }),

    IMPORT_PICTURE_REQUEST: 'IMPORT_PICTURE_REQUEST',
    IMPORT_PICTURE_SUCCESS: 'IMPORT_PICTURE_SUCCESS',
    IMPORT_PICTURE_FAILED: 'IMPORT_PICTURE_FAILED',
    importPicture: (userId, fbId) => ({
        type: actions.IMPORT_PICTURE_REQUEST,
        userId: userId,
        fbId: fbId
    }),

    IMPORT_ID_REQUEST: 'IMPORT_ID_REQUEST',
    IMPORT_ID_SUCCESS: 'IMPORT_ID_SUCCESS',
    IMPORT_ID_FAILED: 'IMPORT_ID_FAILED',
    importId: (userId, fbName) => ({
        type: actions.IMPORT_ID_REQUEST,
        userId: userId,
        fbName: fbName
    }),

};

export default actions;
