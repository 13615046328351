const actions = {
    DELETE_REQUEST: 'DELETE_REQUEST',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    DELETE_FAILED: 'DELETE_FAILED',
    deleteImage: (resource, id) => ({
        type: actions.DELETE_REQUEST,
        resource: resource,
        id: id
    }),

    DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
    downloadImage: (resource, id, url) => ({
        type: actions.DOWNLOAD_REQUEST,
        id: id,
        resource: resource,
        url: url
    }),

};

export default actions;
