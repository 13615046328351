import actions from './actions';
import {DeviceModel} from "../../models/device-model";

const initState = {
    pagination: {
        pageSize: 20,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    importProgress: 0,
    items: [],
    data: new DeviceModel({}),
    error: null

};


const deviceModelReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_DEVICE_MODELS_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_DEVICE_MODELS_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {
                ...state,
                loading: false,
                items: action.data,
                sort: action.sort,
                filters: action.filters,
                pagination: pagination
            };

        case actions.GET_DEVICE_MODELS_FAILED:
            return {...state, loading: false, items: [], error: action.error};

        case actions.GET_DEVICE_MODEL_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_DEVICE_MODEL_SUCCESS:
            return {...state, loading: false, data: action.data};

        case actions.GET_DEVICE_MODEL_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.CLEAR_DEVICE_MODEL:
            return {...state, data: new DeviceModel({})};

        case actions.CREATE_DEVICE_MODEL_REQUEST:
            return {...state, loading: true, error: null};

        case actions.CREATE_DEVICE_MODEL_SUCCESS:
            return {...state, loading: false};

        case actions.CREATE_DEVICE_MODEL_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.UPDATE_DEVICE_MODEL_REQUEST:
            return {...state, loading: true, error: null};

        case actions.UPDATE_DEVICE_MODEL_SUCCESS:
            return {...state, loading: false};

        case actions.UPDATE_DEVICE_MODEL_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.DELETE_DEVICE_MODEL_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_DEVICE_MODEL_SUCCESS:
            return {...state, loading: false};

        case actions.DELETE_DEVICE_MODEL_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.IMPORT_DEVICE_MODELS_REQUEST:
            return {...state, loading: true, error: null, importProgress: 0};

        case actions.IMPORT_DEVICE_MODELS_SUCCESS:
            return {...state, loading: false, importProgress: 0};

        case actions.IMPORT_DEVICE_MODELS_FAILED:
            return {...state, loading: false, importProgress: 0, error: action.error};

        case actions.SET_PROGRESS:
            return {...state, importProgress: action.percent}

        case actions.GET_DEVICE_MODELS_PDF_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_DEVICE_MODELS_PDF_SUCCESS:
            return {...state, loading: false};

        case actions.GET_DEVICE_MODELS_PDF_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_DEVICE_MODELS_CSV_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_DEVICE_MODELS_CSV_SUCCESS:
            return {...state, loading: false};

        case actions.GET_DEVICE_MODELS_CSV_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default deviceModelReducer;
