import {takeEvery, select, put, call} from 'redux-saga/effects';
import actions from './actions';
import AuthAPI from '../../api/auth';
import {checkError} from "../api.errors";

function* login(payload) {
    let e = yield call(AuthAPI.login, payload.data);

    let result = checkError(e);

    if (result.success) {
        localStorage.setItem('user', JSON.stringify(e.response.data));
        yield put({
            type: actions.LOGIN_SUCCESS,
            user: e.response.data
        });
    } else {
        yield put({
            type: actions.LOGIN_FAILURE,
            error: result
        });
    }
}

function* logout() {
    let e = yield call(AuthAPI.logout);
    localStorage.removeItem('user');
}

export default function* rootSaga() {
    yield takeEvery('LOGIN_REQUEST', login);
    yield takeEvery('LOGOUT_REQUEST', logout);
}
