import actions from './actions';

let user = JSON.parse(localStorage.getItem('user'));
const initState = user ? { loggedIn: true, error: null, user } : {error: null};


const passwordReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.RECOVER_PASSWORD_REQUEST:
            return {
                data: action.data
            };

        case actions.RECOVER_PASSWORD_SUCCESS:
            return {
                recovered: true,
                message: action.data.message
            };

        case actions.RECOVER_PASSWORD_FAILURE:
            return {
                error: action.error
            };

        case actions.SET_PASSWORD_REQUEST:
            return {
                data: action.data
            };

        case actions.SET_PASSWORD_SUCCESS:
            return {
                set: true,
                message: action.data.message
            };

        case actions.SET_PASSWORD_FAILURE:
            return {
                error: action.error
            };

        default:
            return state;
    }
};

export default passwordReducer;