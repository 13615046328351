import React, {Component} from "react";
import {Form, Icon, Input, Button, Checkbox} from 'antd';
import {Link} from 'react-router-dom'

const WrappedRecoverPasswordForm = Form.create({})(
    class extends Component {

        render() {
            const {getFieldDecorator} = this.props.form;
            const {handleSubmit} = this.props;

            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 8},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
            };

            const tailFormItemLayout = {
                wrapperCol: {
                    xs: {
                        span: 24,
                        offset: 0,
                    },
                    sm: {
                        span: 16,
                        offset: 8,
                    },
                },
            };

            return (
                <div>
                    <h1 style={{
                        margin: '0 auto',
                        background: 'none',
                        padding: '2em 0 1em 0',
                        textAlign: 'center'
                    }}>Przywracanie hasła</h1>

                    <Form {...formItemLayout} onSubmit={handleSubmit}>

                        <Form.Item label="E-mail">
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        type: 'email',
                                        message: 'To nie jest poprawny adres e-mail',
                                    },
                                    {
                                        required: true,
                                        message: 'Proszę wpisać adres e-mail',
                                    },
                                ],
                            })(<Input/>)}
                        </Form.Item>

                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit">
                                Wyślij
                            </Button>
                            <Link to="/login" style={{marginLeft: '1em'}}>
                                Zaloguj się
                            </Link>
                        </Form.Item>

                    </Form>
                </div>
            );
        }
    });

export default WrappedRecoverPasswordForm;