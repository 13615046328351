import {takeEvery, put, call} from 'redux-saga/effects';
import actions from './actions';
import StatAPI from '../../api/stat';
import {checkError} from "../api.errors";
import {getRequestFromState} from "../../models/request";

function* getStat(payload) {
    let e = yield call(StatAPI.getOne);

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.GET_STAT_SUCCESS,
            data: e.response.data
        });
    } else {
        yield put({
            type: actions.GET_STAT_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery('GET_STAT_REQUEST', getStat);
}
