import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {Layout} from "antd";

const {Content, Footer} = Layout;

const LoginLayout = ({children}) => (
    <Layout style={{backgroundColor: '#eee', height: '100%', overflow: 'auto'}}>
        <div style={{margin: '0 auto', padding: '1em'}}>
            {children}
        </div>
        <div style={{textAlign: 'center', background: 'none'}}>Ensteam ©2020</div>
    </Layout>
);

const LoginLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <LoginLayout>
                <Component {...matchProps} />
            </LoginLayout>
        )}/>
    )
};

export default LoginLayoutRoute;
