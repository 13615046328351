import axios from "axios/index";
import {root} from "../redux/api.config";
import {ListRequest} from "../models/request";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'manufacturer';

export default class ManufacturerAPI {

    static getList(params: ListRequest) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'get',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getOne(id) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'get',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static create(data) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'post',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static update(id, data) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'put',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static delete(id) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'delete',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static importManufacturers() {
        const options = {
            url: `${root}/api/import/${resource}s`,
            method: 'get',
            headers: authHeader()
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getListPdf(params: ListRequest) {
        const options = {
            url: `${root}/api/pdf/${resource}s`,
            method: 'get',
            responseType: 'blob',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getListCsv(params: ListRequest) {
        const options = {
            url: `${root}/api/csv/${resource}s`,
            method: 'get',
            responseType: 'blob',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }


}
