import {DeviceModel} from "../../models/device-model";

const actions = {
    GET_DEVICE_MODELS_REQUEST: 'GET_DEVICE_MODELS_REQUEST',
    GET_DEVICE_MODELS_SUCCESS: 'GET_DEVICE_MODELS_SUCCESS',
    GET_DEVICE_MODELS_FAILED: 'GET_DEVICE_MODELS_FAILED',
    getDeviceModels: (params) => ({
        type: actions.GET_DEVICE_MODELS_REQUEST,
        params: params
    }),

    GET_DEVICE_MODEL_REQUEST: 'GET_DEVICE_MODEL_REQUEST',
    GET_DEVICE_MODEL_SUCCESS: 'GET_DEVICE_MODEL_SUCCESS',
    GET_DEVICE_MODEL_FAILED: 'GET_DEVICE_MODEL_FAILED',
    getDeviceModel: (id) => ({
        type: actions.GET_DEVICE_MODEL_REQUEST,
        id: id
    }),

    CLEAR_DEVICE_MODEL: 'CLEAR_DEVICE_MODEL',
    clearDeviceModel: () => ({
        type: actions.CLEAR_DEVICE_MODEL
    }),

    CREATE_DEVICE_MODEL_REQUEST: 'CREATE_DEVICE_MODEL_REQUEST',
    CREATE_DEVICE_MODEL_SUCCESS: 'CREATE_DEVICE_MODEL_SUCCESS',
    CREATE_DEVICE_MODEL_FAILED: 'CREATE_DEVICE_MODEL_FAILED',
    createDeviceModel: (data: DeviceModel) => ({
        type: actions.CREATE_DEVICE_MODEL_REQUEST,
        data: data
    }),

    UPDATE_DEVICE_MODEL_REQUEST: 'UPDATE_DEVICE_MODEL_REQUEST',
    UPDATE_DEVICE_MODEL_SUCCESS: 'UPDATE_DEVICE_MODEL_SUCCESS',
    UPDATE_DEVICE_MODEL_FAILED: 'UPDATE_DEVICE_MODEL_FAILED',
    updateDeviceModel: (id, data: DeviceModel) => ({
        type: actions.UPDATE_DEVICE_MODEL_REQUEST,
        id: id,
        data: data
    }),

    DELETE_DEVICE_MODEL_REQUEST: 'DELETE_DEVICE_MODEL_REQUEST',
    DELETE_DEVICE_MODEL_SUCCESS: 'DELETE_DEVICE_MODEL_SUCCESS',
    DELETE_DEVICE_MODEL_FAILED: 'DELETE_DEVICE_MODEL_FAILED',
    deleteDeviceModel: (id) => ({
        type: actions.DELETE_DEVICE_MODEL_REQUEST,
        id: id
    }),

    IMPORT_DEVICE_MODELS_REQUEST: 'IMPORT_DEVICE_MODELS_REQUEST',
    IMPORT_DEVICE_MODELS_SUCCESS: 'IMPORT_DEVICE_MODELS_SUCCESS',
    IMPORT_DEVICE_MODELS_FAILED: 'IMPORT_DEVICE_MODELS_FAILED',
    importDeviceModels: () => ({
        type: actions.IMPORT_DEVICE_MODELS_REQUEST,
    }),

    SET_PROGRESS: 'SET_PROGRESS',
    setProgress: (percent) => ({
        type: actions.SET_PROGRESS,
        percent: percent
    }),

    GET_DEVICE_MODELS_PDF_REQUEST: 'GET_DEVICE_MODELS_PDF_REQUEST',
    GET_DEVICE_MODELS_PDF_SUCCESS: 'GET_DEVICE_MODELS_PDF_SUCCESS',
    GET_DEVICE_MODELS_PDF_FAILED: 'GET_DEVICE_MODELS_PDF_FAILED',
    getDeviceModelsPdf: (params) => ({
        type: actions.GET_DEVICE_MODELS_PDF_REQUEST,
        params: params
    }),

    GET_DEVICE_MODELS_CSV_REQUEST: 'GET_DEVICE_MODELS_CSV_REQUEST',
    GET_DEVICE_MODELS_CSV_SUCCESS: 'GET_DEVICE_MODELS_CSV_SUCCESS',
    GET_DEVICE_MODELS_CSV_FAILED: 'GET_DEVICE_MODELS_CSV_FAILED',
    getDeviceModelsCsv: (params) => ({
        type: actions.GET_DEVICE_MODELS_CSV_REQUEST,
        params: params
    }),
}

export default actions;
