import React from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Button, Col, Row, Form, Input, Table, Icon, Tag, Avatar} from "antd";
import {connect} from "react-redux";
import actions from "../../redux/user/actions";
import CheckError from "../../components/checkError";
import DeleteModal from "../../components/deleteModal";
import {Container} from "../Container";
import {root} from "../../redux/api.config";
import {User} from "../../models/user";

const {Content} = Layout;
const {getUsers, deleteUser, importPicture, importId} = actions;

export class UsersContainer extends Container {

    resource = 'users';
    model = new User();
    getResource = (params) => {
        this.props.getUsers(params);
    };

    constructor(props) {
        super(props);
        this.state = {
            userModalVisible: false,
            userModalTitle: '',
            deleteUserVisible: false,
            user: {},
            fullText: '',
        };
    }

    componentDidMount() {
        //this.props.getAllRoles();
        //console.log('componentdidmount');
        if (this.props.users.fullText !== this.state.fullText) {
            this.setState({fullText: this.props.users.fullText});
        }
        this.getResource(this.props[this.resource]);
    };

    /**
     * Importuje zdjęcie z facebook.com
     * @param record
     */
    importPicture = (record) => {
        this.props.importPicture(record.id, record.fb_name);
    }

    /**
     * Importuje id z facebook.com
     * @param record
     */
    importId = (record) => {
        this.props.importId(record.id, record.fb_name);
    }

    /**
     * Drukuje dane
     */
    print() {

    }

    /**
     * Pobiera dane
     */
    download() {

    }

    /**
     * @description Otwiera okno usuwania danych
     */
    showDeleteModal = (record) => {
        this.setState({
            user: record,
            deleteModalVisible: true
        });
    }

    /**
     * @description Usuwa dane
     */
    handleDeleteOk = () => {
        this.props.deleteUser(this.state.user.id);
        this.setState({
            user: {},
            deleteModalVisible: false
        });
    }

    /**
     * @description Anuluje usuwanie danych
     */
    handleDeleteCancel = () => {
        this.setState({
            deleteModalVisible: false
        });
    }

    addUser = () => {
        this.props.history.push('/users/users/add');
    }

    /**
     * Uruchomienie filtra i zamknięcie okienka
     * (właściwe fitrowanie obługiwane jest przez metodę handleTableChange)
     * @param selectedKeys
     * @param confirm
     */
    handleSearch = (selectedKeys, confirm) => {
        console.log(selectedKeys);
        confirm();
    }

    /**
     * Wyczyszczenie filtra i zamknięcie okienka
     * (właściwe czyszczenie fitra obługiwane jest przez metodę handleTableChange)
     * @param clearFilters
     */
    handleReset = (clearFilters) => {
        clearFilters();
    }

    handleResetFullText = () => {
        this.setState({fullText: ''}, () => {
            this.filterFullText('');
        });
    };

    handleKeyDownFullText = (e) => {
        if (e.key === 'Enter') {
            this.filterFullText(this.state.fullText);
        }
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            ...this.getColumnSearchProps('id'),
            defaultSortOrder: this.props[this.resource].sort.id || null,
            filteredValue: this.props[this.resource].filters.id || null,
        }, {
            title: '',
            key: 'picture',
            render: (text, record) => {
                if (record.picture) {
                    return <Avatar src={root + record.picture}/>;
                } else {
                    return null;
                }
            }
        }, {
            title: 'Imię',
            dataIndex: 'firstname',
            key: 'firstname',
            sorter: true,
            ...this.getColumnSearchProps('firstname'),
            defaultSortOrder: this.props[this.resource].sort.firstname || null,
            filteredValue: this.props[this.resource].filters.firstname || null,
        }, {
            title: 'Nazwisko',
            dataIndex: 'surname',
            key: 'surname',
            sorter: true,
            ...this.getColumnSearchProps('surname'),
            defaultSortOrder: this.props[this.resource].sort.surname || null,
            filteredValue: this.props[this.resource].filters.surname || null,
        }, {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ...this.getColumnSearchProps('email'),
            defaultSortOrder: this.props[this.resource].sort.email || null,
            filteredValue: this.props[this.resource].filters.email || null,
        }, {
            title: 'Role',
            key: 'role_names',
            render: (text, record) => {
                if (record.role_ids) {
                    const roleNames = record.role_ids.split(', ');
                    return (
                        roleNames.map((value, key) => {
                            let role = this.props.dictionaries.roles[value];
                            //let role = rolesById[value];
                            if (role) {
                                return <Tag key={key} title={role.name}
                                            color={role.color ? role.color : ''}>
                                    {role.icon ? <Icon type={role.icon}/> : role.name}
                                </Tag>
                            } else {
                                return <Tag key={key}>{value}</Tag>;
                            }
                        })
                    )
                } else {
                    return '';
                }
            },
            ...this.getColumnSearchProps('role_names'),
            defaultSortOrder: this.props[this.resource].sort.role_names || null,
            filteredValue: this.props[this.resource].filters.role_names || null,
        }, {
            title: 'Akcje',
            key: 'action',
            fixed: 'right',
            width: 160,
            render: (text, record) => (
                <span>
                <Button.Group>
                    <Button type="primary" icon="edit" title="Edytuj" onClick={(e) => {
                        this.props.history.push('/users/users/edit/' + record.id);
                        e.stopPropagation();
                    }}></Button>
                    <Button type="primary" icon="delete" title="Usuń" onClick={(e) => {
                        this.showDeleteModal(record);
                        e.stopPropagation();
                    }}></Button>
                </Button.Group>
                    <p style={{fontSize: '0.8em'}}>{record.update_time}</p>
                </span>
            ),
        }
        ];

        return <div>
            <Layout style={{padding: '0 24px 24px'}}>
                <PageHeader>Użytkownicy</PageHeader>
                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <Row>
                        <Col span={4}>
                            <Button.Group>
                                <Button icon="printer" onClick={this.print} title="Drukuj"></Button>
                                <Button icon="download" onClick={this.download}
                                        title="Pobierz"></Button>
                                <Button icon="reload" onClick={this.refresh}
                                        title="Odśwież"></Button>
                            </Button.Group>
                        </Col>
                        <Col span={20} style={{textAlign: 'right'}}>
                            <Form layout="inline">
                                <Form.Item>
                                    <Input
                                        placeholder="Szukaj"
                                        value={this.state.fullText}
                                        onChange={(e) => this.setState({fullText: e.target.value})}
                                        onKeyDown={this.handleKeyDownFullText}
                                        suffix={<Icon type="close"
                                                      onClick={this.handleResetFullText}/>}
                                        addonAfter={<Icon type="search"
                                                          onClick={(e) => this.filterFullText(this.state.fullText)}/>}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" icon="plus" onClick={this.addUser}>Dodaj
                                        użytkownika</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>

                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <CheckError error={this.props.users.error} history={this.props.history}/>
                    <Table
                        pagination={{
                            ...this.props.users.pagination,
                            pageSizeOptions: ['5', '10', '20'], // ręczna opcja liczby wyświetlanych rekordow na stronie
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: this.showTotalItemsDialog,
                        }}
                        columns={columns}
                        scroll={{x: 1000}}
                        dataSource={this.props.users.items}
                        loading={this.props.users.loading}
                        rowKey="id"
                        onChange={this.handleTableChange}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    //history.push(this.props.url + '/' + record.id)
                                },
                                onMouseEnter: () => {
                                },  // mouse enter row
                            };
                        }
                        }
                    />

                    <DeleteModal
                        title="Usuwanie użytkownika"
                        content={<div><p>Czy na pewno usunąć użytkownika:</p><p><i>{this.state.user.username}</i></p>
                        </div>}
                        visible={this.state.deleteModalVisible}
                        onDelete={this.handleDeleteOk}
                        onCancel={this.handleDeleteCancel}
                    >
                    </DeleteModal>
                </Content>
            </Layout>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    users: state.Users,
    dictionaries: state.Dictionaries
});

export default connect(mapStateToProps, {
    getUsers,
    deleteUser,
    importPicture,
    importId
})(UsersContainer);
