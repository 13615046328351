import axios from "axios/index";
import {root} from "../redux/api.config";
import {ListRequest} from "../models/request";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'stat';

export default class StatAPI {

    static getOne() {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'get',
            headers: authHeader()
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

}
