import React from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Button, Col, Row, Form, Input, Table, Icon, Tag} from "antd";
import {Role} from "../../models/role";
import {connect} from "react-redux";
import actions from "../../redux/role/actions";
import CheckError from "../../components/checkError";
import DeleteModal from "../../components/deleteModal";
import {Container} from "../Container";

const {Content} = Layout;
const {getRoles, deleteRole} = actions;

export class RolesContainer extends Container {

    state = {
        roleModalVisible: false,
        roleModalTitle: '',
        deleteRoleVisible: false,
        role: {},
        fullText: ''
    };

    resource = 'roles';
    model = new Role();
    getResource = (params) => {
        this.props.getRoles(params);
    };

    componentDidMount() {
        if (this.props.roles.fullText !== this.state.fullText) {
            this.setState({fullText: this.props.roles.fullText});
        }
        this.getResource(this.props[this.resource]);
    };

    /**
     * Drukuje dane
     */
    print() {

    }

    /**
     * Pobiera dane
     */
    download() {

    }

    /**
     * @description Otwiera okno usuwania danych
     */
    showDeleteModal = (record) => {
        this.setState({
            role: record,
            deleteModalVisible: true
        });
    }

    /**
     * @description Usuwa dane
     */
    handleDeleteOk = () => {
        this.props.deleteRole(this.state.role.id);
        this.setState({
            role: {},
            deleteModalVisible: false
        });
    }

    /**
     * @description Anuluje usuwanie danych
     */
    handleDeleteCancel = () => {
        this.setState({
            deleteModalVisible: false
        });
    }

    addRole = () => {
        this.props.history.push('/users/roles/add');
    }

    /**
     * Dodaje nową rolę
     */
    handleAddRoleSave = () => {
        const form = this.roleFormRef.props.form;
        form.validateFields((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }
            //console.log('Received values of form: ', values);

            //wysyłka danych do serwera
            const role = new Role();
            this.props.createRole(role.getData(values), true);

            //wyczyszczenie formularza
            form.resetFields();

            //zamknięcie okna edycji
            this.setState({
                roleModalVisible: false
            });
        });
    }

    /**
     * Uruchomienie filtra i zamknięcie okienka
     * (właściwe fitrowanie obługiwane jest przez metodę handleTableChange)
     * @param selectedKeys
     * @param confirm
     */
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    }

    /**
     * Wyczyszczenie filtra i zamknięcie okienka
     * (właściwe czyszczenie fitra obługiwane jest przez metodę handleTableChange)
     * @param clearFilters
     */
    handleReset = (clearFilters) => {
        clearFilters();
    }

    handleResetFullText = () => {
        this.setState({fullText: ''}, () => {
            this.filterFullText('');
        });
    };

    handleKeyDownFullText = (e) => {
        if (e.key === 'Enter') {
            this.filterFullText(this.state.fullText);
        }
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            ...this.getColumnSearchProps('id'),
            defaultSortOrder: this.props[this.resource].sort.id || null,
            filteredValue: this.props[this.resource].filters.id || null,
        }, {
            title: 'Rola',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ...this.getColumnSearchProps('name'),
            defaultSortOrder: this.props[this.resource].sort.name || null,
            filteredValue: this.props[this.resource].filters.name || null,
        }, {
            title: 'Ikona',
            key: 'icon',
            render: (text, record) => {
                if (record.icon) {
                    return <Tag color={record.color ? record.color : ''}>
                        <Icon type={record.icon}/>
                    </Tag>;
                } else {
                    return '';
                }
            }
        }, {
            title: 'Akcje',
            key: 'action',
            render: (text, record) => (
                <Button.Group>
                    <Button type="primary" icon="edit" title="Edytuj" onClick={(e) => {
                        this.props.history.push('/users/roles/edit/' + record.id);
                        e.stopPropagation();
                    }}></Button>
                    <Button type="primary" icon="delete" title="Usuń" onClick={(e) => {
                        this.showDeleteModal(record);
                        e.stopPropagation();
                    }}></Button>
                </Button.Group>
            ),
        }
        ];
        return <div>
            <Layout style={{padding: '0 24px 24px'}}>
                <PageHeader>Role</PageHeader>
                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <Row>
                        <Col span={4}>
                            <Button.Group>
                                <Button icon="printer" onClick={this.print} title="Drukuj"></Button>
                                <Button icon="download" onClick={this.download}
                                        title="Pobierz"></Button>
                            </Button.Group>
                        </Col>
                        <Col span={20} style={{textAlign: 'right'}}>
                            <Form layout="inline">
                                <Form.Item>
                                    <Input
                                        placeholder="Szukaj"
                                        value={this.state.fullText}
                                        onChange={(e) => this.setState({fullText: e.target.value})}
                                        onKeyDown={this.handleKeyDownFullText}
                                        suffix={<Icon type="close"
                                                      onClick={this.handleResetFullText}/>}
                                        addonAfter={<Icon type="search"
                                                          onClick={(e) => this.filterFullText(this.state.fullText)}/>}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" icon="plus" onClick={this.addRole}>Dodaj
                                        rolę</Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>

                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <CheckError error={this.props.roles.error} history={this.props.history}/>
                    <Table
                        pagination={{
                            ...this.props.roles.pagination,
                            pageSizeOptions: ['5', '10', '20'], // ręczna opcja liczby wyświetlanych rekordow na stronie
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: this.showTotalItemsDialog,
                        }}
                        columns={columns}
                        dataSource={this.props.roles.items}
                        loading={this.props.roles.loading}
                        rowKey="id"
                        onChange={this.handleTableChange}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    //history.push(this.props.url + '/' + record.id)
                                },
                                onMouseEnter: () => {
                                },  // mouse enter row
                            };
                        }
                        }
                    />

                    <DeleteModal
                        title="Usuwanie roli"
                        content={<div><p>Czy na pewno usunąć rolę:</p><p><i>{this.state.role.name}</i></p>
                        </div>}
                        visible={this.state.deleteModalVisible}
                        onDelete={this.handleDeleteOk}
                        onCancel={this.handleDeleteCancel}
                    >
                    </DeleteModal>
                </Content>
            </Layout>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    roles: state.Roles
});

export default connect(mapStateToProps, {
    getRoles,
    deleteRole
})(RolesContainer);
