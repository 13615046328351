const actions = {
    SET_SIDEBAR_COLLAPSED: 'SET_SIDEBAR_COLLAPSED',
    setSidebarCollapsed: (collapsed) => ({
        type: actions.SET_SIDEBAR_COLLAPSED,
        collapsed: collapsed
    }),

};

export default actions;
