import actions from './actions';
import {Product} from "../../models/product";

const initState = {
    pagination: {
        pageSize: 20,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    importProgress: 0,
    items: [],
    data: new Product({}),
    error: null

};


const deviceModelReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_PRODUCTS_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_PRODUCTS_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {
                ...state, loading: false, items: action.data,
                sort: action.sort,
                filters: action.filters,
                pagination: pagination
            };

        case actions.GET_PRODUCTS_FAILED:
            return {...state, loading: false, items: [], error: action.error};

        case actions.GET_PRODUCT_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_PRODUCT_SUCCESS:
            return {...state, loading: false, data: action.data};

        case actions.GET_PRODUCT_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.CLEAR_PRODUCT:
            return {...state, data: new Product({})};

        case actions.CREATE_PRODUCT_REQUEST:
            return {...state, loading: true, error: null};

        case actions.CREATE_PRODUCT_SUCCESS:
            return {...state, loading: false};

        case actions.CREATE_PRODUCT_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.UPDATE_PRODUCT_REQUEST:
            return {...state, loading: true, error: null};

        case actions.UPDATE_PRODUCT_SUCCESS:
            return {...state, loading: false};

        case actions.UPDATE_PRODUCT_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.DELETE_PRODUCT_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_PRODUCT_SUCCESS:
            return {...state, loading: false};

        case actions.DELETE_PRODUCT_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.IMPORT_PRODUCTS_REQUEST:
            return {...state, loading: true, error: null, importProgress: 0};

        case actions.IMPORT_PRODUCTS_SUCCESS:
            return {...state, loading: false, importProgress: 0};

        case actions.IMPORT_PRODUCTS_FAILED:
            return {...state, loading: false, importProgress: 0, error: action.error};

        case actions.SET_PROGRESS:
            return {...state, importProgress: action.percent}

        case actions.GET_PRODUCTS_PDF_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_PRODUCTS_PDF_SUCCESS:
            return {...state, loading: false};

        case actions.GET_PRODUCTS_PDF_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_PRODUCTS_CSV_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_PRODUCTS_CSV_SUCCESS:
            return {...state, loading: false};

        case actions.GET_PRODUCTS_CSV_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default deviceModelReducer;
