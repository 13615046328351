import React, {Component} from "react";
import {Upload, Button, Form, Modal, Input, Select, Icon, message} from 'antd';

const EditModal = Form.create({
    //ustawia wartości w polach formularza
    mapPropsToFields(props) {
        let fields = {};
        for (const i in props.data) {
            fields[i] = Form.createFormField({
                value: props.data[i],
            });
        }
        return fields;
    }
})
(
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: false,
                imageUrl: props.data.icon
            }
        }

        componentDidUpdate(prevProps) {
            if (prevProps.data.icon !== this.props.data.icon) {
                this.setState({imageUrl: this.props.data.icon})
            }
        }

        render() {
            const {visible, onCancel, onSave, form, title} = this.props;
            const {getFieldDecorator} = form;

            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 5},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 19},
                },
            };

            return (
                <Modal
                    title={title}
                    visible={visible}
                    style={{top: 20}}
                    width={800}
                    onOk={onSave}
                    onCancel={onCancel}
                    footer={[
                        <Button icon="close" key="back" onClick={onCancel}>
                            Anuluj
                        </Button>,
                        <Button icon="save" key="submit" type="primary" onClick={onSave}>
                            Zapisz
                        </Button>,
                    ]}
                >
                    <Form {...formItemLayout}>

                        <Form.Item label="ID">
                            {getFieldDecorator('id', {
                                rules: [{required: true, message: 'Wprowadź id kategorii'}],
                            })(
                                <Input/>
                            )}
                        </Form.Item>

                        <Form.Item label="Nazwa">
                            {getFieldDecorator('name', {
                                rules: [{required: true, message: 'Wprowadź nazwę kategorii'}],
                            })(
                                <Input/>
                            )}
                        </Form.Item>

                    </Form>
                </Modal>
            );
        }
    });

export default EditModal;
