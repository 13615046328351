import {Category} from "../../models/category";

const actions = {
    GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILED: 'GET_CATEGORIES_FAILED',
    getCategories: (params) => ({
        type: actions.GET_CATEGORIES_REQUEST,
        params: params
    }),

    GET_CATEGORY_REQUEST: 'GET_CATEGORY_REQUEST',
    GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_FAILED: 'GET_CATEGORY_FAILED',
    getCategory: (id) => ({
        type: actions.GET_CATEGORY_REQUEST,
        id: id
    }),

    CLEAR_CATEGORY: 'CLEAR_CATEGORY',
    clearCategory: () => ({
        type: actions.CLEAR_CATEGORY
    }),

    CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
    CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
    CREATE_CATEGORY_FAILED: 'CREATE_CATEGORY_FAILED',
    createCategory: (data: Category) => ({
        type: actions.CREATE_CATEGORY_REQUEST,
        data: data
    }),

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILED: 'UPDATE_CATEGORY_FAILED',
    updateCategory: (id, data: Category) => ({
        type: actions.UPDATE_CATEGORY_REQUEST,
        id: id,
        data: data
    }),

    DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILED: 'DELETE_CATEGORY_FAILED',
    deleteCategory: (id) => ({
        type: actions.DELETE_CATEGORY_REQUEST,
        id: id
    }),

    IMPORT_CATEGORIES_REQUEST: 'IMPORT_CATEGORIES_REQUEST',
    IMPORT_CATEGORIES_SUCCESS: 'IMPORT_CATEGORIES_SUCCESS',
    IMPORT_CATEGORIES_FAILED: 'IMPORT_CATEGORIES_FAILED',
    importCategories: () => ({
        type: actions.IMPORT_CATEGORIES_REQUEST,
    }),

    GET_CATEGORIES_PDF_REQUEST: 'GET_CATEGORIES_PDF_REQUEST',
    GET_CATEGORIES_PDF_SUCCESS: 'GET_CATEGORIES_PDF_SUCCESS',
    GET_CATEGORIES_PDF_FAILED: 'GET_CATEGORIES_PDF_FAILED',
    getCategoriesPdf: (params) => ({
        type: actions.GET_CATEGORIES_PDF_REQUEST,
        params: params
    }),

    GET_CATEGORIES_CSV_REQUEST: 'GET_CATEGORIES_CSV_REQUEST',
    GET_CATEGORIES_CSV_SUCCESS: 'GET_CATEGORIES_CSV_SUCCESS',
    GET_CATEGORIES_CSV_FAILED: 'GET_CATEGORIES_CSV_FAILED',
    getCategoriesCsv: (params) => ({
        type: actions.GET_CATEGORIES_CSV_REQUEST,
        params: params
    }),

};

export default actions;
