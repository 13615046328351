import {all} from 'redux-saga/effects';

import dictionarySagas from './dictionary/saga';
import userSagas from './user/saga';
import authSagas from './auth/saga';
import registerSagas from './register/saga';
import passwordSagas from './password/saga';
import roleSagas from './role/saga';
import sessionSagas from './session/saga';
import importSagas from './import/saga';
import imageSagas from './image/saga';
import manufacturerSagas from './manufacturer/saga';
import manufacturerListSagas from './manufacturer-list/saga';
import deviceModelSagas from './device-model/saga';
import productSagas from './product/saga';
import categorySagas from './category/saga';
import categoryListSagas from './category-list/saga';
import statSagas from './stat/saga';

export default function* rootSaga(getState) {
    yield all([
        dictionarySagas(),
        userSagas(),
        authSagas(),
        registerSagas(),
        passwordSagas(),
        roleSagas(),
        sessionSagas(),
        importSagas(),
        imageSagas(),
        manufacturerSagas(),
        manufacturerListSagas(),
        deviceModelSagas(),
        productSagas(),
        categorySagas(),
        categoryListSagas(),
        statSagas()
    ]);
}
