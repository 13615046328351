import actions from './actions';

const initState = {
    pagination: {
        pageSize: 10,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    error: null,
    dictionaries: [],
    items: [],
    dictionaryId: null,
    status: 'start',
    //zasoby, do których wymagany jest dostęp w wielu miejscach aplikacji
    roles: [],
    all: {}
};


const dictionaryReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_DICTIONARIES_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_DICTIONARIES_SUCCESS:
            return {
                ...state,
                dictionaries: action.data,
                sort: action.sort,
                filters: action.filters,
                loading: false
            };

        case actions.GET_DICTIONARIES_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_ALL_DICTIONARIES_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_ALL_DICTIONARIES_SUCCESS:
            return {
                ...state,
                roles: action.roles.data,
                all: action.all,
                loading: false
            };

        case actions.GET_ALL_DICTIONARIES_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_ALL_ROLES_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_ALL_ROLES_SUCCESS:
            return {...state, roles: action.data, loading: false};

        case actions.GET_ALL_ROLES_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_ALL_COUNTRIES_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_ALL_COUNTRIES_SUCCESS:
            return {...state, countries: action.data, loading: false};

        case actions.GET_ALL_COUNTRIES_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_DICTIONARY_ITEMS_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_DICTIONARY_ITEMS_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {
                ...state,
                dictionaryId: action.dictionaryId,
                items: action.data,
                pagination: pagination,
                loading: false
            };

        case actions.GET_DICTIONARY_ITEMS_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.CREATE_DICTIONARY_ITEM_REQUEST:
            return {...state, loading: true, error: null};

        case actions.CREATE_DICTIONARY_ITEM_SUCCESS:
            return {...state, loading: false, status: 'refresh'};

        case actions.CREATE_DICTIONARY_ITEM_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.UPDATE_DICTIONARY_ITEM_REQUEST:
            return {...state, loading: true, error: null};

        case actions.UPDATE_DICTIONARY_ITEM_SUCCESS:
            return {...state, loading: false, status: 'refresh'};

        case actions.UPDATE_DICTIONARY_ITEM_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.DELETE_DICTIONARY_ITEM_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_DICTIONARY_ITEM_SUCCESS:
            return {...state, loading: false, status: 'refresh'};

        case actions.DELETE_DICTIONARY_ITEM_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default dictionaryReducer;
