import {User} from "../../models/user";

const actions = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    register: (data: User) => ({
        type: actions.REGISTER_REQUEST,
        data: data
    }),

    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
};

export default actions;
