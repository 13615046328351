import actions from './actions';

const initState = {};

const registerReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.REGISTER_REQUEST:
            return {
                registering: true
            };

        case actions.REGISTER_SUCCESS:
            return {
                message: 'Formularz rejestracyjny został wysłany'
            };

        case actions.REGISTER_FAILURE:
            return {
                error: action.error
            };

        default:
            return state;
    }
};

export default registerReducer;