
const actions = {
    GET_SESSIONS_REQUEST: 'GET_SESSIONS_REQUEST',
    GET_SESSIONS_SUCCESS: 'GET_SESSIONS_SUCCESS',
    GET_SESSIONS_FAILED: 'GET_SESSIONS_FAILED',
    getSessions: (params) => ({
        type: actions.GET_SESSIONS_REQUEST,
        params: params
    }),

    GET_SESSION_REQUEST: 'GET_SESSION_REQUEST',
    GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
    GET_SESSION_FAILED: 'GET_SESSION_FAILED',
    getSession: (id) => ({
        type: actions.GET_SESSION_REQUEST,
        id: id
    }),

    /*CLEAR_SESSION: 'CLEAR_SESSION',
    clearSession: () => ({
        type: actions.CLEAR_SESSION
    }),*/

    DELETE_SESSION_REQUEST: 'DELETE_SESSION_REQUEST',
    DELETE_SESSION_SUCCESS: 'DELETE_SESSION_SUCCESS',
    DELETE_SESSION_FAILED: 'DELETE_SESSION_FAILED',
    deleteSession: (id) => ({
        type: actions.DELETE_SESSION_REQUEST,
        id: id
    })

};

export default actions;
