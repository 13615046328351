import actions from './actions';
import {Session} from "../../models/session";

const initState = {
    pagination: {
        pageSize: 10,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    items: [],
    data: new Session({}),
    error: null,
    status: 'start'
};


const sessionReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_SESSIONS_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_SESSIONS_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {...state, loading: false, items: action.data,
                sort: action.sort,
                filters: action.filters,
                pagination: pagination, status: 'got-list'};

        case actions.GET_SESSIONS_FAILED:
            return {...state, loading: false, items: [], error: action.error};

        case actions.GET_SESSION_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_SESSION_SUCCESS:
            return {...state, loading: false, data: action.data};

        case actions.GET_SESSION_FAILED:
            return {...state, loading: false, error: action.error};

        /*case actions.CLEAR_SESSION:
            return {...state, data: new Session({})};*/

        case actions.DELETE_SESSION_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_SESSION_SUCCESS:
            return {...state, loading: false, status: 'refresh'};

        case actions.DELETE_SESSION_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default sessionReducer;
