import {Manufacturer} from "../../models/manufacturer";

const actions = {
    GET_MANUFACTURERS_REQUEST: 'GET_MANUFACTURERS_REQUEST',
    GET_MANUFACTURERS_SUCCESS: 'GET_MANUFACTURERS_SUCCESS',
    GET_MANUFACTURERS_FAILED: 'GET_MANUFACTURERS_FAILED',
    getManufacturers: (params) => ({
        type: actions.GET_MANUFACTURERS_REQUEST,
        params: params
    }),

    GET_MANUFACTURER_REQUEST: 'GET_MANUFACTURER_REQUEST',
    GET_MANUFACTURER_SUCCESS: 'GET_MANUFACTURER_SUCCESS',
    GET_MANUFACTURER_FAILED: 'GET_MANUFACTURER_FAILED',
    getManufacturer: (id) => ({
        type: actions.GET_MANUFACTURER_REQUEST,
        id: id
    }),

    CLEAR_MANUFACTURER: 'CLEAR_MANUFACTURER',
    clearManufacturer: () => ({
        type: actions.CLEAR_MANUFACTURER
    }),

    CREATE_MANUFACTURER_REQUEST: 'CREATE_MANUFACTURER_REQUEST',
    CREATE_MANUFACTURER_SUCCESS: 'CREATE_MANUFACTURER_SUCCESS',
    CREATE_MANUFACTURER_FAILED: 'CREATE_MANUFACTURER_FAILED',
    createManufacturer: (data: Manufacturer) => ({
        type: actions.CREATE_MANUFACTURER_REQUEST,
        data: data
    }),

    UPDATE_MANUFACTURER_REQUEST: 'UPDATE_MANUFACTURER_REQUEST',
    UPDATE_MANUFACTURER_SUCCESS: 'UPDATE_MANUFACTURER_SUCCESS',
    UPDATE_MANUFACTURER_FAILED: 'UPDATE_MANUFACTURER_FAILED',
    updateManufacturer: (id, data: Manufacturer) => ({
        type: actions.UPDATE_MANUFACTURER_REQUEST,
        id: id,
        data: data
    }),

    DELETE_MANUFACTURER_REQUEST: 'DELETE_MANUFACTURER_REQUEST',
    DELETE_MANUFACTURER_SUCCESS: 'DELETE_MANUFACTURER_SUCCESS',
    DELETE_MANUFACTURER_FAILED: 'DELETE_MANUFACTURER_FAILED',
    deleteManufacturer: (id) => ({
        type: actions.DELETE_MANUFACTURER_REQUEST,
        id: id
    }),

    IMPORT_MANUFACTURERS_REQUEST: 'IMPORT_MANUFACTURERS_REQUEST',
    IMPORT_MANUFACTURERS_SUCCESS: 'IMPORT_MANUFACTURERS_SUCCESS',
    IMPORT_MANUFACTURERS_FAILED: 'IMPORT_MANUFACTURERS_FAILED',
    importManufacturers: () => ({
        type: actions.IMPORT_MANUFACTURERS_REQUEST,
    }),

    GET_MANUFACTURERS_PDF_REQUEST: 'GET_MANUFACTURERS_PDF_REQUEST',
    GET_MANUFACTURERS_PDF_SUCCESS: 'GET_MANUFACTURERS_PDF_SUCCESS',
    GET_MANUFACTURERS_PDF_FAILED: 'GET_MANUFACTURERS_PDF_FAILED',
    getManufacturersPdf: (params) => ({
        type: actions.GET_MANUFACTURERS_PDF_REQUEST,
        params: params
    }),

    GET_MANUFACTURERS_CSV_REQUEST: 'GET_MANUFACTURERS_CSV_REQUEST',
    GET_MANUFACTURERS_CSV_SUCCESS: 'GET_MANUFACTURERS_CSV_SUCCESS',
    GET_MANUFACTURERS_CSV_FAILED: 'GET_MANUFACTURERS_CSV_FAILED',
    getManufacturersCsv: (params) => ({
        type: actions.GET_MANUFACTURERS_CSV_REQUEST,
        params: params
    }),

};

export default actions;
