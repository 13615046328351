import actions from './actions';
import {Role} from "../../models/role";

const initState = {
    pagination: {
        pageSize: 10,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    items: [],
    data: new Role({}),
    error: null,
    status: 'start',
};


const roleReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_ROLES_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_ROLES_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {...state, loading: false, items: action.data,
                sort: action.sort,
                filters: action.filters,
                pagination: pagination, status: 'got-list'};

        case actions.GET_ROLES_FAILED:
            return {...state, loading: false, items: [], error: action.error};

        case actions.GET_ROLE_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_ROLE_SUCCESS:
            return {...state, loading: false, data: action.data};

        case actions.GET_ROLE_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.CLEAR_ROLE:
            return {...state, data: new Role({})};

        case actions.CREATE_ROLE_REQUEST:
            return {...state, loading: true, error: null};

        case actions.CREATE_ROLE_SUCCESS:
            return {...state, loading: false, status: 'refresh'};

        case actions.CREATE_ROLE_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.UPDATE_ROLE_REQUEST:
            return {...state, loading: true, error: null};

        case actions.UPDATE_ROLE_SUCCESS:
            return {...state, loading: false, status: 'refresh'};

        case actions.UPDATE_ROLE_FAILED:
            return {...state, loading: false, error: action.error};

        case actions.DELETE_ROLE_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_ROLE_SUCCESS:
            return {...state, loading: false, status: 'refresh'};

        case actions.DELETE_ROLE_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default roleReducer;
