const actions = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    login: (username, password) => ({
        type: actions.LOGIN_REQUEST,
        data: {
            username: username,
            password: password
        }
    }),

    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    logout: () => ({
        type: actions.LOGOUT_REQUEST
    })
};

export default actions;
