import {takeEvery, put, call} from 'redux-saga/effects';
import actions from './actions';
import ImportAPI from '../../api/import';
import {checkError} from "../api.errors";

function* importData(payload) {
    let e;
    if (payload.id) {
        e = yield call(ImportAPI.importForId, payload.resource, payload.id);
    } else {
        e = yield call(ImportAPI.import, payload.resource);
    }

    let result = checkError(e);

    if (result.success) {
        yield put({
            type: actions.IMPORT_DATA_SUCCESS
        });
    } else {
        yield put({
            type: actions.IMPORT_DATA_FAILED,
            error: result
        });
    }
}

export default function* rootSaga() {
    yield takeEvery(actions.IMPORT_DATA_REQUEST, importData);
}
