import axios from "axios/index";
import {root} from "../redux/api.config";
import {ListRequest} from "../models/request";
import {authHeader} from "../helpers/AuthHeader";

const resource = 'model';

export default class DeviceModelAPI {

    static getList(params: ListRequest) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'get',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getOne(id) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'get',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static create(data) {
        const options = {
            url: `${root}/api/${resource}`,
            method: 'post',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static update(id, data) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'put',
            headers: {
                ...authHeader(),
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static delete(id) {
        const options = {
            url: `${root}/api/${resource}/${id}`,
            method: 'delete',
            headers: authHeader(),
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getDeviceModelsByManufacturer(manufacturerId) {
        const params = new FormData();
        params.append('type', 'model');
        params.append('mfr', manufacturerId);
        params.append('p', 1);
        const options = {
            url: `https://www.precisionroller.com/ajax/catalog-browser.php?ajp=ddb`,
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            data: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static importDeviceModels(records) {
        const options = {
            url: `${root}/api/import/${resource}s`,
            method: 'post',
            headers: authHeader(),
            data: records
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getListPdf(params: ListRequest) {
        const options = {
            url: `${root}/api/pdf/${resource}s`,
            method: 'get',
            responseType: 'blob',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }

    static getListCsv(params: ListRequest) {
        const options = {
            url: `${root}/api/csv/${resource}s`,
            method: 'get',
            responseType: 'blob',
            headers: authHeader(),
            params: params
        };
        return axios(options).then(response => ({response}))
            .catch(error => ({error}));
    }


}
