import React from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Button, Col, Row, Form, Input, Table, Icon} from "antd";
import {Category} from "../../models/category";
import {connect} from "react-redux";
import actions from "../../redux/category/actions";
import CheckError from "../../components/checkError";
import DeleteModal from "../../components/deleteModal";
import {Container} from "../Container";
import EditModal from "./EditModal";

const {Content} = Layout;
const {
    getCategories,
    deleteCategory,
    createCategory,
    updateCategory,
    importCategories,
    getCategoriesPdf,
    getCategoriesCsv
} = actions;

export class CategoriesContainer extends Container {

    state = {
        editModalVisible: false,
        editModalTitle: '',
        deleteModalVisible: false,
        category: {},
        fullText: ''
    };

    resource = 'categories';
    model = new Category();
    getResource = (params) => {
        this.props.getCategories(params);
    };

    componentDidMount() {
        if (this.props.categories.fullText !== this.state.fullText) {
            this.setState({fullText: this.props.categories.fullText});
        }
        this.getResource(this.props[this.resource]);
    };

    /**
     * Drukuje dane
     */
    print = () => {
        this.props.getCategoriesPdf({});
    }

    /**
     * Pobiera dane
     */
    download = () => {
        this.props.getCategoriesCsv({});
    }

    /**
     * @description Otwiera okno usuwania danych
     */
    showDeleteModal = (record) => {
        this.setState({
            category: record,
            deleteModalVisible: true
        });
    }

    /**
     * @description Usuwa dane
     */
    handleDeleteOk = () => {
        this.props.deleteCategory(this.state.category.id);
        this.setState({
            category: {},
            deleteModalVisible: false
        });
    }

    /**
     * @description Anuluje usuwanie danych
     */
    handleDeleteCancel = () => {
        this.setState({
            deleteModalVisible: false
        });
    }

    /**
     * Otwiera okno dodawania danych
     * @param record
     */
    showAddModal = (record) => {
        this.setState({
            editModalVisible: true,
            editModalTitle: 'Dodaj kategorię',
            category: new Category()
        });
    }

    /**
     * Otwiera okno edycji danych
     * @param record
     */
    showEditModal = (record) => {
        this.setState({
            editModalVisible: true,
            editModalTitle: 'Edytuj kategorię ' + record.name,
            category: record
        });
    }

    /**
     * Zapisuje dane
     */
    handleSave = () => {
        const form = this.editFormRef.props.form;
        form.validateFields((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }
            //console.log(values);
            //wysyłka danych do serwera
            const m = new Category();
            if (this.state.category.id) {
                this.props.updateCategory(this.state.category.id, m.getData(values));
            } else {
                this.props.createCategory(m.getData(values));
            }

            //console.log('Received values of form: ', m.getData(values));

            //wyczyszczenie formularza
            form.resetFields();

            //zamknięcie okna edycji
            this.setState({
                editModalVisible: false
            });
        });
    }

    /**
     * Anuluje edycję danych
     */
    handleCancel = () => {
        this.setState({
            editModalVisible: false
        });
    }

    /**
     * Uruchomienie filtra i zamknięcie okienka
     * (właściwe fitrowanie obługiwane jest przez metodę handleTableChange)
     * @param selectedKeys
     * @param confirm
     */
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    }

    /**
     * Wyczyszczenie filtra i zamknięcie okienka
     * (właściwe czyszczenie fitra obługiwane jest przez metodę handleTableChange)
     * @param clearFilters
     */
    handleReset = (clearFilters) => {
        clearFilters();
    }

    handleResetFullText = () => {
        this.setState({fullText: ''}, () => {
            this.filterFullText('');
        });
    };

    handleKeyDownFullText = (e) => {
        if (e.key === 'Enter') {
            this.filterFullText(this.state.fullText);
        }
    }

    /**
     * Zwraca referencję do formularza edycji
     * @param formRef
     */
    saveEditFormRef = (formRef) => {
        this.editFormRef = formRef;
    }

    importCategories = () => {
        this.props.importCategories();
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.id || null,
            filteredValue: this.props[this.resource].filters.id || null,
            ...this.getColumnSearchProps('id'),
        }, {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.name || null,
            filteredValue: this.props[this.resource].filters.name || null,
            ...this.getColumnSearchProps('name'),
        }, {
            title: 'Akcje',
            key: 'action',
            render: (text, record) => (
                <Button.Group>
                    <Button type="primary" icon="edit" title="Edytuj" onClick={(e) => {
                        this.showEditModal(record);
                        e.stopPropagation();
                    }}></Button>
                    <Button type="primary" icon="delete" title="Usuń" onClick={(e) => {
                        this.showDeleteModal(record);
                        e.stopPropagation();
                    }}></Button>
                </Button.Group>
            ),
        }
        ];
        return <div>
            <Layout style={{padding: '0 24px 24px'}}>
                <PageHeader>Kategorie produktów</PageHeader>
                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <Row>
                        <Col span={4}>
                            <Button.Group>
                                <Button icon="printer" onClick={this.print} title="Drukuj"></Button>
                                <Button icon="download" onClick={this.download}
                                        title="Pobierz"></Button>
                                <Button icon="reload" onClick={this.refresh}
                                        title="Odśwież"></Button>
                            </Button.Group>
                        </Col>
                        <Col span={20} style={{textAlign: 'right'}}>
                            <Form layout="inline">
                                <Form.Item>
                                    <Input
                                        placeholder="Szukaj"
                                        value={this.state.fullText}
                                        onChange={(e) => this.setState({fullText: e.target.value})}
                                        onKeyDown={this.handleKeyDownFullText}
                                        suffix={<Icon type="close"
                                                      onClick={this.handleResetFullText}/>}
                                        addonAfter={<Icon type="search"
                                                          onClick={(e) => this.filterFullText(this.state.fullText)}/>}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="default" icon="plus" onClick={this.showAddModal}>Dodaj</Button>
                                </Form.Item>

                            </Form>
                        </Col>
                    </Row>
                </Content>

                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <CheckError error={this.props.categories.error} history={this.props.history}/>
                    <Table
                        pagination={{
                            ...this.props.categories.pagination,
                            pageSizeOptions: ['5', '10', '20'], // ręczna opcja liczby wyświetlanych rekordow na stronie
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: this.showTotalItemsDialog,
                        }}
                        columns={columns}
                        dataSource={this.props.categories.items}
                        loading={this.props.categories.loading}
                        rowKey="id"
                        onChange={this.handleTableChange}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    //history.push(this.props.url + '/' + record.id)
                                },
                                onMouseEnter: () => {
                                },  // mouse enter row
                            };
                        }
                        }
                    />

                    <EditModal
                        wrappedComponentRef={this.saveEditFormRef}
                        title={this.state.editModalTitle}
                        visible={this.state.editModalVisible}
                        onSave={this.handleSave}
                        onCancel={this.handleCancel}
                        data={this.state.category}
                    >
                    </EditModal>

                    <DeleteModal
                        title="Usuwanie danych kategorii"
                        content={<div><p>Czy na pewno usunąć dane producenta: <i>{this.state.category.name}</i>?</p>
                        </div>}
                        visible={this.state.deleteModalVisible}
                        onDelete={this.handleDeleteOk}
                        onCancel={this.handleDeleteCancel}
                    >
                    </DeleteModal>
                </Content>
            </Layout>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    categories: state.Categories
});

export default connect(mapStateToProps, {
    getCategories,
    deleteCategory,
    createCategory,
    updateCategory,
    importCategories,
    getCategoriesPdf,
    getCategoriesCsv
})(CategoriesContainer);
