import React from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Button, Col, Row, Form, Input, Table, Icon, Tag} from "antd";
import {connect} from "react-redux";
import actions from "../../redux/session/actions";
import CheckError from "../../components/checkError";
import DeleteModal from "../../components/deleteModal";
import {Container} from "../Container";
import {Session} from "../../models/session";

const {Content} = Layout;
const {getSessions, deleteSession} = actions;

export class SessionsContainer extends Container {

    state = {
        sessionModalVisible: false,
        sessionModalTitle: '',
        deleteSessionVisible: false,
        session: {},
        fullText: ''
    };

    resource = 'sessions';
    model = new Session();
    getResource = (params) => {
        this.props.getSessions(params);
    };

    componentDidMount() {
        if (this.props.sessions.fullText !== this.state.fullText) {
            this.setState({fullText: this.props.sessions.fullText});
        }
        this.getResource(this.props[this.resource]);
    };

    /**
     * Drukuje dane
     */
    print() {

    }

    /**
     * Pobiera dane
     */
    download() {

    }

    /**
     * @description Otwiera okno usuwania danych
     */
    showDeleteModal = (record) => {
        this.setState({
            session: record,
            deleteModalVisible: true
        });
    }

    /**
     * @description Usuwa dane
     */
    handleDeleteOk = () => {
        this.props.deleteSession(this.state.session.id);
        this.setState({
            session: {},
            deleteModalVisible: false
        });
    }

    /**
     * @description Anuluje usuwanie danych
     */
    handleDeleteCancel = () => {
        this.setState({
            deleteModalVisible: false
        });
    }

    /**
     * Uruchomienie filtra i zamknięcie okienka
     * (właściwe fitrowanie obługiwane jest przez metodę handleTableChange)
     * @param selectedKeys
     * @param confirm
     */
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    }

    /**
     * Wyczyszczenie filtra i zamknięcie okienka
     * (właściwe czyszczenie fitra obługiwane jest przez metodę handleTableChange)
     * @param clearFilters
     */
    handleReset = (clearFilters) => {
        clearFilters();
    }

    handleResetFullText = () => {
        this.setState({fullText: ''}, () => {
            this.filterFullText('');
        });
    };

    handleKeyDownFullText = (e) => {
        if (e.key === 'Enter') {
            this.filterFullText(this.state.fullText);
        }
    }

    render() {
        const columns = [{
            title: 'Użytkownik',
            dataIndex: 'user_name',
            key: 'user_name',
            sorter: true,
            ...this.getColumnSearchProps('user_name'),
            defaultSortOrder: this.props[this.resource].sort.user_name || null,
            filteredValue: this.props[this.resource].filters.user_name || null,
        }, {
            title: 'Urządzenie',
            key: 'device_type_name',
            render: (text, record) => {
                if (record.device_type_icon) {
                    return <span><Icon type={record.device_type_icon}/> {record.device_type_name}</span>;
                } else {
                    return record.device_type_name;
                }
            }
        }, {
            title: 'OS',
            dataIndex: 'os_name',
            key: 'os_name'
        }, {
            title: 'Przeglądarka',
            dataIndex: 'browser_name',
            key: 'browser_name'
        }, {
            title: 'Czas zalogowania',
            dataIndex: 'insert_time',
            key: 'insert_time',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.insert_time || null,
        }, {
            title: 'Czas wygaśnięcia',
            dataIndex: 'expired',
            key: 'expired',
            sorter: true,
            defaultSortOrder: this.props[this.resource].sort.expired || null,
        }, {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            ...this.getColumnSearchProps('ip'),
            filteredValue: this.props[this.resource].filters.ip || null,
        }, {
            title: 'Akcje',
            key: 'action',
            render: (text, record) => (
                <Button.Group>
                    <Button type="primary" icon="delete" title="Usuń" onClick={(e) => {
                        this.showDeleteModal(record);
                        e.stopPropagation();
                    }}></Button>
                </Button.Group>
            ),
        }
        ];
        return <div>
            <Layout style={{padding: '0 24px 24px'}}>
                <PageHeader>Sesje</PageHeader>
                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <Row>
                        <Col span={4}>
                            <Button.Group>
                                <Button icon="printer" onClick={this.print} title="Drukuj"></Button>
                                <Button icon="download" onClick={this.download}
                                        title="Pobierz"></Button>
                            </Button.Group>
                        </Col>
                        <Col span={20} style={{textAlign: 'right'}}>
                            <Form layout="inline">
                                <Form.Item>
                                    <Input
                                        placeholder="Szukaj"
                                        value={this.state.fullText}
                                        onChange={(e) => this.setState({fullText: e.target.value})}
                                        onKeyDown={this.handleKeyDownFullText}
                                        suffix={<Icon type="close"
                                                      onClick={this.handleResetFullText}/>}
                                        addonAfter={<Icon type="search"
                                                          onClick={(e) => this.filterFullText(this.state.fullText)}/>}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>

                <Content style={{
                    background: '#fff',
                    padding: 24,
                    margin: 0,
                }}>
                    <CheckError error={this.props.sessions.error} history={this.props.history}/>
                    <Table
                        pagination={{
                            ...this.props.sessions.pagination,
                            pageSizeOptions: ['5', '10', '20'], // ręczna opcja liczby wyświetlanych rekordow na stronie
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: this.showTotalItemsDialog,
                        }}
                        columns={columns}
                        dataSource={this.props.sessions.items}
                        loading={this.props.sessions.loading}
                        rowKey="id"
                        onChange={this.handleTableChange}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    //history.push(this.props.url + '/' + record.id)
                                },
                                onMouseEnter: () => {
                                },  // mouse enter row
                            };
                        }
                        }
                    />

                    <DeleteModal
                        title="Usuwanie sesji"
                        content={<div><p>Czy na pewno usunąć sesję:</p>
                            <p><i>Użytkownik: {this.state.session.user_name}<br/>
                                Czas zalogowania: {this.state.session.insert_time}</i></p>
                        </div>}
                        visible={this.state.deleteModalVisible}
                        onDelete={this.handleDeleteOk}
                        onCancel={this.handleDeleteCancel}
                    >
                    </DeleteModal>
                </Content>
            </Layout>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    sessions: state.Sessions
});

export default connect(mapStateToProps, {
    getSessions,
    deleteSession
})(SessionsContainer);
