import actions from './actions';
import {Manufacturer} from "../../models/manufacturer";

const initState = {
    pagination: {
        pageSize: 20,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    items: [],
    data: new Manufacturer({}),
    error: null

};


const manufacturerReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_MANUFACTURERS_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_MANUFACTURERS_SUCCESS:
            let pagination = {...state.pagination, total: action.total};
            return {...state, loading: false, items: action.data,
                sort: action.sort,
                filters: action.filters,
                pagination: pagination};

        case actions.GET_MANUFACTURERS_FAILED:
            return {...state, loading: false, items: [], error: action.error};


        case actions.GET_MANUFACTURER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_MANUFACTURER_SUCCESS:
            return {...state, loading: false, data: action.data};

        case actions.GET_MANUFACTURER_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.CLEAR_MANUFACTURER:
            return {...state, data: new Manufacturer({})};


        case actions.CREATE_MANUFACTURER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.CREATE_MANUFACTURER_SUCCESS:
            return {...state, loading: false};

        case actions.CREATE_MANUFACTURER_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.UPDATE_MANUFACTURER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.UPDATE_MANUFACTURER_SUCCESS:
            return {...state, loading: false};

        case actions.UPDATE_MANUFACTURER_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.DELETE_MANUFACTURER_REQUEST:
            return {...state, loading: true, error: null};

        case actions.DELETE_MANUFACTURER_SUCCESS:
            return {...state, loading: false};

        case actions.DELETE_MANUFACTURER_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.IMPORT_MANUFACTURERS_REQUEST:
            return {...state, loading: true, error: null};

        case actions.IMPORT_MANUFACTURERS_SUCCESS:
            return {...state, loading: false};

        case actions.IMPORT_MANUFACTURERS_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_MANUFACTURERS_PDF_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_MANUFACTURERS_PDF_SUCCESS:
            return {...state, loading: false};

        case actions.GET_MANUFACTURERS_PDF_FAILED:
            return {...state, loading: false, error: action.error};


        case actions.GET_MANUFACTURERS_CSV_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_MANUFACTURERS_CSV_SUCCESS:
            return {...state, loading: false};

        case actions.GET_MANUFACTURERS_CSV_FAILED:
            return {...state, loading: false, error: action.error};

        default:
            return state;
    }
};

export default manufacturerReducer;
