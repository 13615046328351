import actions from './actions';
import {Manufacturer} from "../../models/manufacturer";

const initState = {
    pagination: {
        pageSize: 1000,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {name: 'asc'},
    loading: false,
    items: [],
    data: new Manufacturer({}),
    error: null
};


const manufacturerListReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_MANUFACTURER_LIST_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_MANUFACTURER_LIST_SUCCESS:
            return {...state, loading: false, items: action.data,
                sort: action.sort,
                filters: action.filters
            };

        case actions.GET_MANUFACTURER_LIST_FAILED:
            return {...state, loading: false, items: [], error: action.error};

        default:
            return state;
    }
};

export default manufacturerListReducer;
