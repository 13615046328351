import React, {Component} from 'react';
import {Redirect, Route, Link} from 'react-router-dom';
import Sidebar from "./Sidebar";
import {Layout, Icon} from "antd";

const {Header, Content, Footer} = Layout;

const DashboardLayout = ({children, ...rest}) => {
    return (
        <Layout>
            <Sidebar/>
            <Layout>
                <Header style={{background: '#fff', padding: '0 48px', textAlign: 'right'}}>
                    <Link to="/login"><Icon type="poweroff" /> Logout</Link>
                </Header>
                <Content style={{margin: '24px 16px 0'}}>
                    {children}
                </Content>
                <Footer style={{textAlign: 'center'}}>Ensteam ©2020</Footer>
            </Layout>
        </Layout>
    )
}

const DashboardLayoutRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            localStorage.getItem('user')
                ? <DashboardLayout>
                    <Component {...matchProps} />
                </DashboardLayout>
                : <Redirect to={{pathname: '/login', state: {from: matchProps.location}}}/>
        )}/>
    )
};

export default DashboardLayoutRoute;
