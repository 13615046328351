import React, {Component} from "react";
import WrappedRecoverPasswordForm from "./RecoverPasswordForm";
import {connect} from "react-redux";
import actions from "../../redux/password/actions";
import CheckError from "../../components/checkError";
import InfoAlert from "../../components/infoAlert";

const {recoverPassword} = actions;

export class RecoverPasswordContainer extends Component {

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (nextProps.password.recovered) {
            //nextProps.history.push('/');
        }
    }

    /**
     * Zwraca referencję do formularza
     * @param formRef
     */
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            //przerwanie procesu w przypadku wystąpienia błędów na formularzu
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);

            this.props.recoverPassword(values);

            //wyczyszczenie formularza
            form.resetFields();
        });
    }

    render() {
        return (<div style={{width: '550px'}}>
            <CheckError error={this.props.password.error}/>
            <InfoAlert message={this.props.password.message}/>
            <WrappedRecoverPasswordForm
                wrappedComponentRef={this.saveFormRef}
                handleSubmit={this.handleSubmit}
            />
        </div>);
    }
}

const mapStateToProps = (state) => ({
    password: state.Password,
});

export default connect(mapStateToProps, {
    recoverPassword
})(RecoverPasswordContainer);