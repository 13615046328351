/**
 * Funkcja zwraca informację o ewentualnym błędzie (kod i treść) w wyniku zapytania http (axios)
 * @param e - wynik zapytania http w postaci obiektu
 * @returns {{message: string, status: number}}
 */
export function checkError(e) {
    let status = 0;
    let msg = '';
    let success = true;
    //jest poprawna odpowiedź serwera
    if (e.response) {
        status = e.response.status;
        //jest poprawna odpowiedź serwera, ale są w niej zawarte błędy
        if (e.response.status !== 200) {
            msg = e.response.statusText;
        }
    }
    //błędna odpowiedź serwera, tj. taka, której status przekracza kod 2xx
    if (e.error) {
        success = false;
        //odpowiedź błędna, zawierająca szczegóły błędu (np. kod)
        if (e.error.response) {
            status = e.error.response.status;
            if (e.error.response.data) {
                //console.log(e.error.response.data);
                msg = e.error.response.data.message;
            } else {
                msg = 'API error';
            }
        } else if (e.error.request) {
            msg = 'API error. The request was made but no response was received';
        } else {
            //błąd nieokreślony
            msg = e.error.message;
        }
        //sprawdzenie środowiska pracy
        if (process.env.NODE_ENV === 'development') {
            msg += ' (METHOD: ' + e.error.config.method + ' URL: ' + e.error.config.url + ')';
        }
    }
    return {
        status: status,
        message: msg,
        success: success
    };
}