import React, {Component} from "react";
import PageHeader from "../../components/pageHeader";
import {Layout, Row, Col, Card, Icon} from "antd";
import {connect} from "react-redux";
import actions from "../../redux/stat/actions";

const {Meta} = Card;

const {getStat} = actions;

export class HomeContainer extends Component {

    componentDidMount() {
        this.props.getStat();
    }

    render() {
        //console.log(this.props.stat.data);
        const iconStyles = {fontSize: '9em', marginTop: '0.2em', marginBottom: '0.2em'};
        const coverStyles = {backgroundColor: '#ddd', textAlign: 'center'};
        return <div>
            <Layout style={{padding: '0 24px 24px'}}>
                <Row gutter={16}>
                    <Col xs={24} md={12} lg={6}>
                        <Card
                            hoverable
                            cover={<div style={coverStyles}><Icon style={iconStyles} type="shop"/></div>}
                            onClick={() => {
                                this.props.history.push('/store/manufacturers')
                            }}
                        >
                            <Meta title="Producenci"
                                  description={"Producenci urządzeń drukujących (" + this.props.stat.data.manufacturer_count + ")"}/>
                        </Card>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <Card
                            hoverable
                            cover={<div style={coverStyles}><Icon style={iconStyles} type="printer"/></div>}
                            onClick={() => {
                                this.props.history.push('/store/models')
                            }}
                        >
                            <Meta title="Modele"
                                  description={"Modele urządzeń drukujących (" + this.props.stat.data.model_count + ")"}/>
                        </Card>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <Card
                            hoverable
                            cover={<div style={coverStyles}><Icon style={iconStyles} type="bars"/></div>}
                            onClick={() => {
                                this.props.history.push('/store/categories')
                            }}
                        >
                            <Meta title="Kategorie"
                                  description={"Kategorie produktów (" + this.props.stat.data.category_count + ")"}/>
                        </Card>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <Card
                            hoverable
                            cover={<div style={coverStyles}><Icon style={iconStyles} type="tag"/></div>}
                            onClick={() => {
                                this.props.history.push('/store/products')
                            }}
                        >
                            <Meta title="Produkty"
                                  description={"Części do urządzeń drukujących (" + this.props.stat.data.product_count + ")"}/>
                        </Card>
                    </Col>
                </Row>
            </Layout>
        </div>;
    }
}

const mapStateToProps = (state) => ({
    stat: state.Stat
});

export default connect(mapStateToProps, {
    getStat
})(HomeContainer);
