import React, {Component} from "react";
import {Progress} from "antd";

export default class extends Component {
    render() {
        if (this.props.data.loading) {
            return <Progress percent={this.props.data.importProgress}/>
        } else {
            return null;
        }
    }
}
