import React, {Component} from 'react';
import {Alert} from "antd";

export default class CheckError extends Component {

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (nextProps.error && nextProps.error.status === 401 && nextProps.error !== this.props.error) {
            if (this.props.history) {
                this.props.history.push('/login');
            }
        }
    }

    render() {
        if (this.props.error) {
            let description = '';
            if (process.env.NODE_ENV === 'development') {
                description = 'Wystąpił błąd numer ' + this.props.error.status + ': ';
            }
            description += this.props.error.message;
            return (
                <Alert
                    message="Błąd"
                    description={description}
                    type="error"
                    closable
                    onClose={this.props.onErrorClose}
                    style={{width: '100%', margin: '1em 0'}}
                />
            );
        }
        return null;
    }
}
