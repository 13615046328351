import actions from './actions';

const initState = {
    pagination: {
        pageSize: 1000,
        current: 1
    },
    filters: {},
    fullText: '',
    sort: {},
    loading: false,
    items: [],
    data: {
        manufacturer_count: 0,
        model_count: 0,
        product_count: 0,
        category_count: 0
    },
    error: null
};


const statReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_STAT_REQUEST:
            return {...state, loading: true, error: null};

        case actions.GET_STAT_SUCCESS:
            return {...state, loading: false, data: action.data,
                sort: action.sort,
                filters: action.filters
            };

        case actions.GET_STAT_FAILED:
            return {...state, loading: false, data: [], error: action.error};

        default:
            return state;
    }
};

export default statReducer;
