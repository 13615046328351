import {isEmpty} from "../helpers/Utility";

/**
 * Struktura parametrów w zapytaniu listującym API
 */
export interface ListRequest {
    page: number,
    recordsPerPage: number,
    sort: any,
    filter: any,
    full_text: string
}

/**
 * Zamienia słowo określające kierunek sortowania na właściwy dla zapytania API
 * @param dir
 * @returns {string}
 */
export function getSortDir(dir) {
    if (dir === 'descend') {
        return 'desc';
    } else {
        return 'asc';
    }
}

/**
 * Zamienia strukturę stanu na strukturę parametrów zapytania API
 * @param state
 */
export function getRequestFromState(state): ListRequest {
    let result = {};
    //model jest potrzebny, aby pozamieniać nazwy pól na zgodne z API
    const model = state.data;

    //stronicowanie
    if (state.pagination && state.pagination.current) {
        result = {
            recordsPerPage: state.pagination.pageSize,
            page: state.pagination.current
        };
    }

    //sortowanie
    //Z jakiegoś powodu API nie przyjmuje pustego obiektu sortowania, dlatego zamieniany jest na null
    if (isEmpty(state.sort)) {
        result.sort = null;
    } else {
        result.sort = {};
        for (let i in state.sort) {
            result.sort[model.getAPIProperty(i)] = getSortDir(state.sort[i]);
        }
    }

    //filtrowanie
    if (state.filters && Object.keys(state.filters).length > 0) {
        result.filter = {};
        for (let i in state.filters) {
            result.filter[model.getAPIProperty(i)] = state.filters[i];
        }
    }

    if (state.fullText) {
        result.full_text = state.fullText;
    }

    return result;
}
